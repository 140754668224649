import React, {useEffect, useState} from 'react';
import AsideProvider from '../asideProvider'
import ContainProvider from '../containProvider'
import FavoriteCheck from '../favoriteCheck'
import { metaPixelService } from '../../services/metaPixel.service';

import './providers.scss'

export const ProvidersCoupons = (props) => {
    const displayName = ProvidersCoupons.name;

    const [isAll, setIsAll] = useState(true);

    const toggleCategories = (isAll) => setIsAll(!isAll);


    useEffect( () => {
        var data = {content_type: 'product', content_ids: props.providerId, currency: 'mxn', content_category: 'provider',  contents : [{id: props.providerId, quantity: 1 }]}
        metaPixelService.trackEvent('track','ViewContent', data)        
    }, [props.providerId])    

    return (
        <div className="box_contain_providers">
            <AsideProvider urlImage={props.model.UrlImageVideo}
                           urlVideo={props.model.UrlVideo}
                           toggleCategories={toggleCategories}
                           isAll={isAll}
            />
            <ContainProvider {...props.model}
                             isAll={isAll}
                             urlImage={props.model.UrlImageVideo}
                             urlVideo={props.model.UrlVideo}
                             toggleCategories={toggleCategories}
            />
        </div>
    );
}
