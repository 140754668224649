import React, { useState } from 'react';


const ItemPayMethod = ({ data, payOrder }) => {
    

    return (
        <button type="button"
            className="item_paymethod trans"
            onClick={()=> payOrder(data.PaymentMethodId) }>
            <img src={data.UrlLogo} alt="" />
        </button>
    )
}

export default ItemPayMethod