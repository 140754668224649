import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { metaPixelService } from '../../services/metaPixel.service'
import './footer.scss'

const Footer = (props) => {
    const [currentYear, setCurrentYear] = useState(0);

    useEffect(() => {
        const year = new Date();
        setCurrentYear(year.getFullYear());
    }, []);

    return (
        <footer className="wrapper_footer">
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-sm-4 box_sociales">
                        <h6>Síguenos en</h6>
                        <p>
                            <span>
                                <a target="_blank" href="https://www.facebook.com/Red-God%C3%ADnez-112619633741809">
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                            </span>
                            {/*<span>
                                <a target="_blank" href="https://www.youtube.com/channel/UCvX18UUFBbrGrokwqrGBlKw">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </span>
                            <span>
                                <a target="_blank" href="https://twitter.com/redvoucher">
                                    <i className="fab fa-twitter-square"></i>
                                </a>
                            </span>*/}
                        </p>
                    </div>
                    <div className="col-sm-4 box_legal">
                        <h6>Información legal</h6>
                        <div className="box_flex justify_around align_center">
                            <p>
                                <Link
                                    to="/notice-privacy"
                                    className=""
                                >
                                    Aviso de privacidad
                </Link>
                            </p>
                            <p>
                                <Link
                                    to="/terms-and-conditions"
                                    className=""
                                >
                                    Términos y condiciones
                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4 box_contacto">
                        <h6>Contacto</h6>
                        <p>
                            <Link
                                    to="#"
                                    className=""
                                    onClick={(e) => {
                                        metaPixelService.simpleEvent('track','Contact');
                                        window.location.href = "mailto: " + props.MailContact;
                                        e.preventDefault();
                                    }}
                                >
                                    <i className="fas fa-envelope"></i> {props.MailContact}
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="row pie">
                    <div className="col-sm-12">
                        <span>{currentYear} Red Godínez. Todos los derechos reservados.</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
