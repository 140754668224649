import { cartHook } from "../../hooks/cart.hook";

export const getUser = () => dispatch => {
    fetch("/api/Account/IsAuthenticated", { method: 'GET' })
        .then(response => response.json())
        .then(result => {
            dispatch({
                type: "SET_USER",
                payload: { isAuthenticated: result.IsAuthenticated, userName: result.User, paymentMethods: result.PaymentMethods, activeSubscription: result.ActiveSubscription, showCancelSubscriptionButton: result.ShowCancelSubscriptionButton },
            });

            if (result.SubscriptionProduct.length > 0) {
                result.SubscriptionProduct.forEach((item) => cartHook.addItem(item));
            } else {
                cartHook.deleteItem(1)
            }
        })
        .catch(error => {
            console.log('error', error)
        });
}
export const setModal = () => dispatch => {
    dispatch ({
      type: "Set_Modal",
    });
  };