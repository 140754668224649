import { callApi } from './service'

export const registerService = {
    getRegisterReferernce
}

async function getRegisterReferernce() {
    return callApi('/api/Account/GetRegisterReference', 'Get')
        .then((result) => {
            return result;
        }).catch((error) => {
            console.log(error);
            return [];
        });
}