import React, { useState } from 'react'
import { Collapse } from 'reactstrap';
import PurchasedItemDetails from '../purchasedItemDetails'
import './purchasedItem.scss'

const PurchasedItem = (props) => {
    const [collapse, setCollapse] = useState(false);
    const [activeChevron, setStateChevron] = useState('')

    function toggle() {
        setCollapse(!collapse);
        setStateChevron(activeChevron === '' ? 'open' : '')
    }

    return (
        <div className="wrapper_purchased_item">

            <div className="head_purchased_item" onClick={toggle}>
                <div className="row text-center justify-content-around">
                    <div className="col first_data">
                        <span className={`ico_chevron ${activeChevron}`} >
                            <i className="fas fa-chevron-down"></i>
                        </span>
                        <span className="col_unic">
                            <h6 className="ttl_info">Fecha de pedido</h6>
                            <p className="p_info">{props.Date}</p>
                        </span>
                    </div>
                    <div className="col">
                        <h6 className="ttl_info">Número de pedido</h6>
                        <p className="p_info">{props.OrderNumber}</p>
                    </div>
                    <div className="col">
                        <h6 className="ttl_info">Total</h6>
                        <p className="p_info">${props.Total}</p>
                    </div>
                    <div className="col">
                        <h6 className="ttl_info">Estatus</h6>
                        <p className="p_info"> <span className="paid">{props.Status}</span> </p>
                    </div>
                </div>
            </div>
            <Collapse
                isOpen={collapse}
                className="body_purchased_item"
            >
                <div className="row align-items-center">
                    <div className="col-md-9 info_item_shopping">
                        {
                            props.Details.map(item => {
                                return <PurchasedItemDetails {...item} />
                            })
                        }
                    </div>

                    <div className="col-md-3">
                        <div className="action_item_shopping">
                            {/*<p><button className="btn btn_primary"><i className="fas fa-download"></i> Descargar folios</button></p>
                        <p ><small className="fw300">Si tuviste algun problema para recibir tus folios, vuelve a intentarlo aquí.</small></p>*/}
                        </div>
                    </div>
                </div>

            </Collapse>
        </div>
    )
}

export default PurchasedItem;