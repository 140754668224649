import React, { Component } from "react";
import { Link } from "react-router-dom"
import { ItemProviderIndividual } from '../wrapperProviderIndividual/itemProviderIndividual'

import './wrapperProviderIndividual.scss'

export class WrapperProviderIndividual extends Component {
    static displayName = WrapperProviderIndividual.name;

    render() {
        return (
            <div className="wrapper_provider_individual">
                <div className="row">
                    {/*<ItemProviderIndividual />
                       <ItemProviderIndividual />*/}

                    <div className="col-sm-6">
                        <a className="item_provider_individual" target="_blank" href="https://www.facebook.com/Red-God%C3%ADnez-112619633741809/">
                            <img src="./imgs/promofb.png" alt="Item Proveedor" />
                        </a>
                    </div>
                    {/*<div className="col-sm-6 mb-5">
                        <Link
                            to='/provider?id=17'
                            className="item_provider_individual"
                        >
                            <img src="./imgs/promouber.png" alt="Item Proveedor" />
                        </Link>
                    </div>*/}
                </div>
            </div>
        )
    }
} 