import React from 'react'
import  HubItemProvider  from "../hubItemProvider";

const HubContainer = ({ providers }) => {

    return (
        <div className="wrapper_hub_container drawer_ttls">
            <div className="mb-4 ttls_hub">
                <h3 className="ttls fw700">
                    <span className="ico_lonchera">
                        <img src="./imgs/ico_lonchera.png" alt="ICO" />
                    </span>
                    ¡Aquí está tu lonchera!</h3>
                <p className="fw300">Que la quincena se quede en tu cuenta</p>
            </div>

            <HubItemProvider providers={providers} />
        </div>
    )
}

export default HubContainer;
