const initialState = {
    isAuthenticated: undefined,
    userName: "",
    paymentMethods: [],
    activeSubscription: false,
    isModalOpen: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                ...action.payload
            };
        case "Set_Modal":
            return {
                ...state,
                isModalOpen: !state.isModalOpen
            };
        default:
            return state;
    }
}
