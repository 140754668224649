import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import BlockScreen from '../components/blockScreen';
import { InstallPWA } from '../components/pwa/installPWA';
import { connect } from 'react-redux';
import WhatsAppButton from '../components/whatsAppButton';
import { setWhatsApp } from '../store/actions/settings.actions';

class Layout extends Component {
    static displayName = Layout.name;
    constructor() {
        super()

        this.state = {
            infoSetting: "",
            blocking: false
        }
    }

    componentWillMount() {
        this.infoSetting();
        if (localStorage.getItem('pruchaseList') == undefined) localStorage.setItem('pruchaseList', "[]");
        if (localStorage.getItem('pruchaseTotal') == undefined) localStorage.setItem('pruchaseTotal', 0);
    }

    infoSetting = () => {
        fetch("/api/Account/SiteInfoSetting", { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                this.setState({ infoSetting: result });
                this.props.setWhatsApp(result.WhatsApp);
            })
            .catch(error => console.log('error', error));
    }

    toggleBlocking = () => {
        this.setState({ blocking: !this.state.blocking });
    }

    render() {
        return <React.Fragment>
            <BlockScreen blocking={this.state.blocking} />
            <Header {...this.state.infoSetting} toggleBlocking={this.toggleBlocking} />
            <main>
                {this.props.children}
            </main>
            <Footer {...this.state.infoSetting} />
            <InstallPWA />
            <WhatsAppButton />

        </React.Fragment>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setWhatsApp: (phone) => {
            dispatch(setWhatsApp(phone))
        }
    }
}

export default connect(null, mapDispatchToProps)(Layout);