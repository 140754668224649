import React, {Component, useEffect} from 'react';
import { Providers } from '../components/providers'
import $ from 'jquery'
import {useLocation} from "react-router-dom";

export const Provider = (props) => {
    const displayName = Provider.name;

    useEffect (() => {
        $('html, body').scrollTop(0);
    }, [])

    return (
        <section className="container_provider">
            <Providers providerId={props.location.search} />
        </section>
    );
}
