import React, { useState } from 'react';
import PurchasedItem from '../purchasedItem'

import './shoppingHistoryWrapper.scss'

const ShoppingHistoryWrapper = ({ shoppingHistory }) => {

    const style = {
        bgProviders: { background: 'url(./imgs/bgheader.jpg)' }
    }

    return (
        <div className="wrapper_shoppingHistory ">
            <div className="bg_sections" style={style.bgProviders}></div>

            <div className="contain_shop_history">
                <h2 className="ttls_sections_shop">Mis compras</h2>
                <div className="boxes_hop_history">
                    {
                        shoppingHistory.map(item => {
                            return <PurchasedItem {...item}/>
                        })
                    }
                </div>
            </div>

        </div>
    );
}

export default ShoppingHistoryWrapper;