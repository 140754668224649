import React, { Component } from "react";
import { Route } from "react-router";
import Layout from "./views/Layout";
import { Home } from "./views/Home";
import { Provider } from "./views/Provider";
import { ShoppingHistory } from "./views/ShoppingHistory";
import { NoticePrivacy } from "./views/NoticePrivacy";
import { TermsAndConditions } from "./views/TermsAndConditions";
import ResetPassword from "./views/ResetPassword";
import ForgotPassword from "./views/ForgotPassword";
import ConfirmedAccount from "./views/ConfirmedAccount";
import PaymentCanceled from "./views/PaymentCanceled";
import { PurchaseOrderDetail } from "./views/PurchaseOrderDetail";
import { PaymentMethod } from "./views/PaymentMethod";
import { CardRegistration } from "./components/cardRegistration/cardRegistration";
import CardVerification from "./components/cardVerification/cardVerification";
import { CardRegistrationItem } from "./components/cardRegistrationItem/cardRegistrationItem";
import "./theme/main.scss";

export default class App extends Component {
    render() {
        return (
            <Layout>
                <Route exact path="/" component={Home} />
                {/*<Route path='/ClientApp/build/checkout' component={PaymentMethod} />*/}
                <Route path="/home/:id" component={Home} />
                <Route path="/active-aside-purchase" component={Home} />
                <Route path="/ClientApp/build" component={Home} />
                <Route path="/provider" component={Provider} />
                <Route path="/shopping-history" component={ShoppingHistory} />
                <Route path="/notice-privacy" component={NoticePrivacy} />
                <Route path="/terms-and-conditions" component={TermsAndConditions} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/confirmed-account" component={ConfirmedAccount} />
                <Route path="/payment-canceled" component={PaymentCanceled} />
                <Route
                    path="/purchase-order-detail/:id"
                    component={PurchaseOrderDetail}
                />
                <Route path="/checkout" component={PaymentMethod} />
                <Route path="/card-registration" component={CardRegistration} />
                <Route path="/cards" component={CardRegistrationItem} />
                <Route path="/card-verification/:id" component={CardVerification} />
            </Layout>
        );
    }
}
