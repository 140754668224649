import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setModal } from '../../store/actions/user.actions';
import ItemPayMethod from './itemPayMethod'
import swal from "sweetalert"
import $ from "jquery"
import { cartHook } from "../../hooks/cart.hook";
import { useHistory } from 'react-router-dom';
import './boxPayMethod.scss'

const BoxPayMethod = ({ onCloseClick, rechargeProducts }) => {
    const dispatch = useDispatch();
    const paymentMethods = useSelector(state => state.user.paymentMethods)
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    const [onlyOneClick, setOnlyOneClick] = useState(false)
    const history = useHistory();
    const payOrder = (paymentMethodId) => {
        if (!onlyOneClick) {
            setOnlyOneClick(true);

            const model =
            {
                Products: cartHook.getItems(rechargeProducts),
                PaymentMethodId: paymentMethodId
            }

            if (parseFloat(localStorage["pruchaseTotal"]) <= 0 && !rechargeProducts) {
                swal({ text: "El monto a pagar debe ser mayor a 0", icon: "error" })
                setOnlyOneClick(false);
            } else {
                if (model.Products.length > 0) {
                    fetch("/api/PaymentMethod/CreateOrder",
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(model)
                        }).then(function (response) {
                            if (response.ok) {
                                const contentType = response.headers.get("content-type");
                                if (contentType.includes("application/json;")) return response.json();
                                else return response.text()
                            }
                        }).then(function (data) {
                            if (data.ValidationMessage) {
                                swal({ text: data.ValidationMessage, icon: "error" });
                                setOnlyOneClick(false);
                            } else {
                                setOnlyOneClick(false);
                                if (data.Model) {
                                    //if (data.SecondModel)
                                    //    localStorage.setItem("secondOrder", JSON.stringify(data.SecondModel));
                                    if (onCloseClick) onCloseClick();
                                    history.push({
                                        pathname: '/checkout',
                                        state: { model: data.Model }
                                    })
                                } else {
                                    $('body').append(data);
                                }
                            }
                        }).catch(function (error) {
                            setOnlyOneClick(false);
                            swal({ text: "Error", icon: "error" })
                        })
                } else {
                    swal({ text: "No hay ningún producto en el carrito de compras", icon: "error" })
                    setOnlyOneClick(false);
                }
            }
        }
    }

    return (
        <div className="box_paymethod">
            {isAuthenticated ? (
                paymentMethods.map((paymentMethod) => {
                    return <ItemPayMethod data={paymentMethod} payOrder={payOrder} />;
                })
            ) : (
                <div className="text-center">
                    <p>
                        <small>¡Por favor inicia sesión para completar tu compra!</small>{" "}
                    </p>
                    <button
                        className="btn btn_border ml-2"
                        onClick={() => dispatch(setModal())}
                    >
                        Entrar
                    </button>
                </div>
            )}
        </div>
    );
}

export default BoxPayMethod

