import React from 'react';

import {Button, Modal, ModalBody} from "reactstrap";

export const ModalError = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="sm" centered={true} backdrop="static">
                <ModalBody>
                    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                        <svg id="closeSVG" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" strokeWidth="4" stroke="#fa3527" fill="#fff" cx="65.1" cy="65.1" r="62.1"/>
                            <line className="path line" fill="none" stroke="#fa3527" strokeWidth="4"
                                  strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                            <line className="path line" fill="none" stroke="#fa3527" strokeWidth="4"
                                  strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                        </svg>
                        <h3 className="text-center mt-3">¡Ups!</h3>
                        <p className="text-center">¡Faltan campos llenar! <br />Revisa tus datos por favor.</p>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <Button size="lg" color="primary" onClick={props.toggle}>De acuerdo</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
    );
}
