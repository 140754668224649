import React, { useState, useEffect } from 'react';

import './providers.scss'
import { Badge, Button, Card, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, Row, Alert } from "reactstrap";
import swal from "sweetalert"
import { HeaderProviders } from "./headerProviders";
import { FaChevronLeft, FaInfo, FaUndo } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import redPay2 from "../../assets/red-pay-logo-xxx.png";
import { ModalError } from "../validations/modalError";
import { metaPixelService } from "../../services/metaPixel.service";
import BoxPayMethod from '../boxPayMethod';

export const ProvidersRecharges = (props) => {
    const user = useSelector((state) => state.user);
    const displayName = ProvidersRecharges.name;
    const factorConversionAPorcentaje = 0.01;

    const [steps, setSteps] = useState(1);
    const [productItems, setProductItems] = useState([]);
    const [userProfileReferences, setUserProfileReferences] = useState([]);
    const [suscriptionItem, setSuscriptionItem] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [dataRecharge, setDataRecharge] = useState({
        reference: "",//
        confirm: "",
        name: "",//
        amount: "",//
    });

    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [validConfirmPhoneNumber, setValidConfirmPhoneNumber] = useState(false);
    const [validReferenceName, setValidreferenceName] = useState(false);
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState('');
    const [addPhone, setAddPhone] = useState(false)
    const [newPhoneNumber, setNewPhoneNumber] = useState(false)
    const [modalError, setModalError] = useState(false);


    useEffect(() => {
        var data = { content_type: 'product', content_ids: props.providerId, currency: 'mxn', content_category: 'provider', contents: [{ id: props.providerId, quantity: 1 }] }
        metaPixelService.trackEvent('track', 'ViewContent', data).then(response => {
            getUserReferences();

        })
    }, [props.providerId])

    useEffect(() => {
        if (props.model) {
            var productsSorted = sortByKey(props.model.CompanyProducts, 'ProductPrice')
            setProductItems(productsSorted)
        }
    }, [props.model])

    useEffect(() => {
        if (user.isAuthenticated && !user.activeSubscription) {
            swal({ text: "Debes realizar el pago de tu suscripción para disfrutar de este beneficio", icon: "warning" }).then(() => {
                window.location.href = "/active-aside-purchase"
            });
        }

        if (user.isAuthenticated == false)
            window.location.href = "/"
    }, [user])


    const onChangeInput = (e) => {
        setDataRecharge({
            ...dataRecharge,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'reference') {
            if (e.target.value.length < 10)
                setValidPhoneNumber(true)
        }

        if (e.target.name === 'reference') {
            if (e.target.value.length === 10)
                setValidPhoneNumber(false)
        }

        if (e.target.name === 'name') {
            if (e.target.value.length <= 3)
                setValidreferenceName(true)
            if (e.target.value.length > 3)
                setValidreferenceName(false)
        }
    }

    const handlerConfirmPhone = (e) => {
        setConfirmPhoneNumber(e.target.value);
        if (e.target.value === dataRecharge.reference || e.target.value.length === 10)
            setValidConfirmPhoneNumber(false)
        if (e.target.value !== dataRecharge.reference || e.target.value.length < 10)
            setValidConfirmPhoneNumber(true)
    }

    const handleAmount = (e) => {
        let productChecked = productItems.find(p => p.ProductId == e.target.value);
        setDataRecharge({ ...dataRecharge, productId: parseInt(e.target.value), amount: productChecked.ProductPrice })
        setSelectedProduct(
            {
                productId: productChecked.ProductId,
                providerId: productChecked.ProviderId,
                name: productChecked.Name,
                cant: 1,
                productPrice: productChecked.ProductPrice,
                PercentageDiscount: productChecked.PercentageDiscount
            });
    }


    const addPhoneNumber = () => {
        setAddPhone(!addPhone)
        setDataRecharge({
            ...dataRecharge,
            reference: '',
            name: ''
        })
        setConfirmPhoneNumber('')
        setValidConfirmPhoneNumber(true)
        setNewPhoneNumber(false)
    }

    const savePhoneNumber = () => setNewPhoneNumber(!newPhoneNumber)


    const toggle = () => setModalError(!modalError);

    const onChangeStepTwo = () => {
        if (newPhoneNumber && dataRecharge.name.length <= 3) return toggle()
        if (addPhone) {
            if (validConfirmPhoneNumber) return toggle();
        }

        if (dataRecharge.reference === '' || dataRecharge.amount === 0) return toggle()

        let cart = JSON.parse(localStorage["pruchaseList"]);
        let suscriptionItemS = cart.find(p => p.ProviderId == 1)?.Items.find(p => p.ProductId == 4) ?? { Total: 0 };
        if (suscriptionItemS != undefined) setSuscriptionItem(suscriptionItemS);
        let total = (dataRecharge.amount * ((1 - (selectedProduct.PercentageDiscount * factorConversionAPorcentaje)))) ;
        setTotalAmount(total);

        setSteps(2);
    }

    function sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    const getUserReferences = () => {
        fetch("/api/Account/GetUserProfileInputValues?providerId=" + props.providerId, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                if (result && result.length > 0) {
                    setUserProfileReferences(result);
                }
                else {
                    setAddPhone(true);
                }
            })
            .catch(error => console.log('error', error));
    }


    const back = () => setSteps(1)
    return (
      <Row className="box_contain_providers">
        <Col xs={12} className="contain_providers">
          <HeaderProviders
            title={props.model.Name}
            paragraph={props.model.Info}
            providerImg={props.model.UrlLogo}
          />
          <div className="wrapper_products_provider">
            <h5 className="ttls fw600 mb-4">¡Haz tu recarga!</h5>
            <Row className="content_products_provider" noGutters>
              <div className="offset-lg-3 col-lg-6">
                {
                  {
                    1: (
                      <Card className="card_reload">
                        <div className="card_reload-header">
                          <h5 className="card_reload-step">
                            <span>1</span> Elige tu recarga
                          </h5>
                          {userProfileReferences.length > 0 && (
                            <>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={addPhoneNumber}
                              >
                                {addPhone ? (
                                  <>
                                    <FaUndo /> <p>Ya tengo un teléfono</p>
                                  </>
                                ) : (
                                  <>
                                    <FaCirclePlus /> <p>Teléfono</p>
                                  </>
                                )}
                              </Button>
                            </>
                          )}
                        </div>
                        {addPhone ? (
                          <>
                            <h6 className="fw400 mb-2">
                              Teléfono al que se le aplicará la recarga
                            </h6>
                            <Row noGutters>
                              <Col lg={6} md={6} sm={6} xs={12}>
                                <FormGroup className="mr-lg-1 mr-md-1 mr-sm-1 mr-0">
                                  <Input
                                    type="text"
                                    name="reference"
                                    onChange={onChangeInput}
                                    maxLength={10}
                                    value={dataRecharge.reference}
                                    placeholder="Número de Teléfono"
                                    onKeyPress={(e) => {
                                      e = e || window.e;
                                      const charCode = e.keyCode || e.which;
                                      const charStr =
                                        String.fromCharCode(charCode);
                                      if (isNaN(charStr)) e.preventDefault();
                                    }}
                                    invalid={validPhoneNumber}
                                  />
                                  <FormFeedback>
                                    Se necesitan 10 dígitos
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg={6} md={6} sm={6} xs={12}>
                                <FormGroup className="ml-lg-1 ml-md-1 ml-sm-1 ml-0">
                                  <Input
                                    type="text"
                                    name="confirmPhoneNumber"
                                    maxLength={10}
                                    onChange={handlerConfirmPhone}
                                    placeholder="Confirmar número de teléfono"
                                    onKeyPress={(e) => {
                                      e = e || window.e;
                                      const charCode = e.keyCode || e.which;
                                      const charStr =
                                        String.fromCharCode(charCode);
                                      if (isNaN(charStr)) e.preventDefault();
                                    }}
                                    value={
                                      addPhone === true
                                        ? confirmPhoneNumber
                                        : ""
                                    }
                                    invalid={
                                      dataRecharge.reference.length === 0
                                        ? false
                                        : validConfirmPhoneNumber
                                    }
                                    disabled={
                                      dataRecharge.reference.length === 0
                                    }
                                  />
                                  <FormFeedback>
                                    Los números telefónicos no conciden
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col
                                xs={12}
                                className="d-flex justify-content-end mb-2"
                              >
                                <FormGroup
                                  className="ml-lg-1 ml-md-1 ml-sm-1 ml-0 checkbox"
                                  check
                                >
                                  <Input
                                    id="saveNumber"
                                    type="checkbox"
                                    onChange={savePhoneNumber}
                                    checked={newPhoneNumber}
                                  />
                                  <Label for="saveNumber" check>
                                    Guardar nuevo número
                                  </Label>
                                </FormGroup>
                              </Col>
                              {newPhoneNumber ? (
                                <Col xs={12}>
                                  <h6 className="fw400 mb-2">
                                    Nombre para identificar
                                  </h6>
                                  <FormGroup className="ml-lg-1 ml-md-1 ml-sm-1 ml-0">
                                    <Input
                                      type="text"
                                      name="name"
                                      onChange={onChangeInput}
                                      placeholder="Nombre para identificar"
                                      value={dataRecharge.name}
                                      invalid={validReferenceName}
                                    />
                                    <FormFeedback>
                                      Se necesitan mínimo 3 carácteres
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              ) : null}
                            </Row>
                          </>
                        ) : (
                          <FormGroup>
                            <h6 className="fw400 mb-2">Número teléfonico</h6>
                            <Input
                              id="reference"
                              name="reference"
                              type="select"
                              onChange={onChangeInput}
                              maxLength={10}
                              onKeyPress={(e) => {
                                e = e || window.e;
                                const charCode = e.keyCode || e.which;
                                const charStr = String.fromCharCode(charCode);
                                if (isNaN(charStr)) e.preventDefault();
                              }}
                              value={
                                addPhone === false ? dataRecharge.reference : ""
                              }
                            >
                              {" "}
                              <option value="0">
                                Selecciona un número telefónico
                              </option>
                              {userProfileReferences &&
                                userProfileReferences.map((item, index) => {
                                  return (
                                    <option
                                      id={item.UserProfileInputValueId}
                                      value={item.Value}
                                    >
                                      {item.KeyName + " - " + item.Value}
                                    </option>
                                  );
                                })}
                            </Input>
                          </FormGroup>
                        )}
                        <div className="card_reload-content">
                          <h6 className="fw400 mb-2">Selecciona un monto</h6>
                          <div className="card_reload-content-container">
                            <div className="card_reload-content-reloads">
                              {productItems.length > 0 &&
                                productItems.map((item, index) => {
                                  return (
                                    <FormGroup className="" check>
                                      <Input
                                        id={"input_" + item.ProductPrice}
                                        name="ProductId"
                                        type="radio"
                                        value={item.ProductId}
                                        onChange={handleAmount}
                                        checked={
                                          dataRecharge.amount ===
                                          item.ProductPrice
                                        }
                                      />
                                      <Label
                                        for={"input_" + item.ProductPrice}
                                        check
                                      >
                                        <p>{item.ProductPrice}</p>
                                        <Button className="btn-info">
                                          <FaInfo />
                                          <div className="btn-info-amount">
                                            <p>{item.Restrictions}</p>
                                          </div>
                                        </Button>
                                      </Label>
                                    </FormGroup>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="card_reload-footer">
                          <Button
                            color="primary"
                            type="button"
                            onClick={onChangeStepTwo}
                          >
                            Siguiente
                          </Button>
                        </div>
                      </Card>
                    ),
                    2: (
                      <Card className="card_reload">
                        <div className="card_reload-header">
                          <h5 className="card_reload-step">
                            <span>2</span> Detalle de tu recarga
                          </h5>
                          <Button size="sm" onClick={back}>
                            <FaChevronLeft /> Regresar
                          </Button>
                        </div>
                        <div className="card_reload-content">
                          <Row noGutters className="card_reload-content-info">
                            <Col lg={4} md={4} sm={4} xs={12}>
                              <h6 className="fw400">Número telefónico:</h6>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={12}>
                              <p className="mb-0">{dataRecharge.reference}</p>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12} className="">
                              <h6 className="fw400">Recarga:</h6>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={12}>
                              <p className="mb-0">{dataRecharge.amount}</p>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12} className="mt-1">
                              <h6 className="fw400">Monto a pagar:</h6>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={12}>
                              <h4 className="mb-0">
                                <Badge color="success"> ${totalAmount}</Badge>
                              </h4>
                            </Col>
                            {suscriptionItem  ? (
                              <Col xs={12}>
                                <div className="mt-2">
                                  <Alert color="warning">*El saldo a favor no puede ser utilizado en recargas.</Alert>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                          <Row noGutters>
                            <Col xs={12}>
                              <h6 className="fw400 mb-2">
                                Elige tu método de pago
                              </h6>
                            </Col>
                            <Col xs={12}>
                              <div className="card_reload-payments">
                                <BoxPayMethod
                                  rechargeProducts={{
                                    ...selectedProduct,
                                    reference: dataRecharge,
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="powered-by">
                                <p className="mb-0">Powered by:</p>
                                <img src={redPay2} alt="" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ),
                  }[steps]
                }
              </div>
            </Row>
          </div>
        </Col>
        <ModalError isOpen={modalError} toggle={toggle} />
      </Row>
    );
}
