import { callApi } from './service'
import { helperService } from '../Helper/helper'

export const metaPixelService = {
    trackEvent,
    customEvent,
    simpleEvent,
    trackRegisterEvent
}

const pixelId = process.env.REACT_APP_PIXEL_ID

async function trackEvent(type, name, data) {
    let pixUser = JSON.parse(sessionStorage.getItem("pixelUserInfo"));
    let newData = { ...data };
    
    if (pixUser) 
        newData = { ...data, ...pixUser }
     
    let id = helperService.generateGuid();
    window.fbq("init", pixelId);
    window.fbq(type, name, newData, { eventID: id });
    var params = {
        type: name, 
        eventId: id,
        pixelData: JSON.stringify(data), 
        user_Info: pixUser ?  JSON.stringify(pixUser) : null
    }
    callApi('/api/MetaPixel/Track', 'POST', params)
        .then((result) => {
            console.log('resultTrack', result);
        });
}

async function customEvent(type, name, data = null) {
    debugger
    window.fbq("init", pixelId);
    if(data){
        window.fbq(type, name, data);
    } else {
        window.fbq(type, name);
    }
}

async function simpleEvent(type, name, data = null) {
    let pixUser = JSON.parse(sessionStorage.getItem("pixelUserInfo"));
    let newData = { ...data };
    let id = helperService.generateGuid();    
    
    if (pixUser) 
        newData = { ...data, ...pixUser }
    window.fbq("init", pixelId);
    window.fbq(type, name, newData, { eventID: id });

    var params = {
        type: name, 
        eventId: id,
        pixelData: data ? JSON.stringify(data): null, 
        user_Info: pixUser ?  JSON.stringify(pixUser) : null
    }
    
    callApi('/api/MetaPixel/Track', 'POST', params)
        .then((result) => {
            console.log(result);
        });
}


async function trackRegisterEvent(type, name, data) {
    let id = helperService.generateGuid();
    
    window.fbq("init", pixelId);
    window.fbq(type, name, { eventID: id });
    var params = {
        type: name, 
        eventId: id,
        pixelData: JSON.stringify(data.registerdata), 
        user_Info: JSON.stringify( data.DataResult)
    }
    callApi('/api/MetaPixel/Track', 'POST', params)
        .then((result) => {
            console.log(result);
        });
}
