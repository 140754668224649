import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import Slider from "react-slick";
import "./couponsSlider.scss";
import { useSelector } from "react-redux";
import { couponService } from "../../services/coupon.service";

const CouponsSlider = () => {
    const activeSubscription = useSelector(
        (state) => state.user.activeSubscription
    );
    const [coupons, setCoupons] = useState([]);
    const [coupon, setCoupon] = useState(undefined);
    const [modal, setModal] = useState(false);

    const toggle = (item) => {
        setModal(!modal);
        setCoupon(item);
    };

    useEffect(() => {
        couponService.getAll().then((result) => {
            setCoupons(result);
        });
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        className: "center",
        centerMode: true,
        slidesToShow: (coupons.length <= 4) ? coupons.length : 4,
        centerPadding: "80px",
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1020,
                settings: {
                    slidesToShow: (coupons.length <= 3) ? coupons.length : 3,
                    centerPadding: "60px",
                },
            },
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: (coupons.length <= 2) ? coupons.length : 2,
                    centerPadding: "60px",
                },
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: (coupons.length <= 2) ? coupons.length : 2,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 540,
                settings: {
                    centerPadding: "60px",
                    slidesToShow: (coupons.length <= 1) ? coupons.length : 1,
                },
            },
            {
                breakpoint: 380,
                settings: {
                    centerPadding: "30px",
                    slidesToShow: (coupons.length <= 1) ? coupons.length : 1,
                },
            },
        ],
    };

    return (
        <div className="wrapper_coupones_slider drawer_ttls">
            <div className="mb-4 ttls_hub">
                <h3 className="ttls fw700">
                    <span className="ico_lonchera">
                        <img src="./imgs/ico_lonchera.png" alt="ICO" />
                    </span>
                    Ya casi es Viernes, dale un vistazo a la Cuponera
                </h3>
            </div>

            <div className="dwr_coupones_slider">
                <Slider {...settings}>
                    {coupons.length > 0 && coupons.map((item) => (
                        <div className="item_coupon" key={item.CouponId}>
                            <div className="item_content_coupon">
                                <img src={item.ImagePath} />
                                <div className="hover_content_coupon">
                                    <div className="content_coup">
                                        <p className="text-center mb-3 boxbtndown">
                                            {activeSubscription && (
                                                <a
                                                    href={item.FilePath}
                                                    className="btn"
                                                    target="_blank"
                                                    download
                                                >
                                                    <i className="fas fa-download"></i> Descargar
                                                </a>
                                            )}
                                        </p>
                                        <p className="text-center mb-0 boxbtnlink">
                                            <span
                                                className="link_more ttls fw500"
                                                onClick={() => toggle(item)}
                                            >
                                                Mayor información
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <Modal className="myModalCoup" isOpen={modal} toggle={toggle}>
                <span className="closeModal" onClick={toggle}>
                    <i className="fas fa-times"></i>
                </span>
                <ModalBody>
                    <div className="row">
                        <div className="col-sm-4 img_coup_details">
                            <img src={coupon && coupon.ImagePath} />
                            <div className="text-center mt-3 mb-3">
                                {activeSubscription && (
                                    <a
                                        href={coupon && coupon.FilePath}
                                        className="btn"
                                        target="_blank"
                                        download
                                    >
                                        <i className="fas fa-download"></i> Descargar
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <h3 className="ttls fw700">
                                {coupon && coupon.CouponTemplateName}
                            </h3>
                            <p className="text-justify p_desc_coup" dangerouslySetInnerHTML={{ __html: coupon && coupon.Description }} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default CouponsSlider;
