import React, { Component } from 'react';
import $ from 'jquery'

export class TermsAndConditions extends Component {
    static displayName = TermsAndConditions.name;


    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    render() {
        const style = {
            bgSections: { background: 'url(./imgs/bglegal.jpg)' }
        }
        return (
            <section className="wrapper_legal ptop">
                <div className="bg_sections" style={style.bgSections}></div>
                <div className="container content_legal">
                    <h2 className="ttls fw600 c_white mb-3">Términos y condiciones</h2>
                    <div className="box_legal">
                        <p>Las presentes condiciones generales de uso de la presente página web, regulan los términos y condiciones de acceso y uso de la misma. El contenido de esta página, así como sus actualizaciones, son propiedad exclusiva de Red Voucher Systems, S.A. de C.V. (“Red Voucher”), cuyo domicilio se encuentra en Texas 39, Colonia Nápoles, Delegación Benito Juárez, C.P.03810, Ciudad de México.</p>
                        <p>Para hacer uso de los servicios e información que se facilitan en la página web, usted reconoce haber leído, entendido y aceptado sus contenidos y por lo tanto manifiesta estar de acuerdo con los mismos por el simple acceso y/o utilización de la página web.</p>
                        <p>Las presentes condiciones generales de uso de la página web regulan el acceso y la utilización de la misma, incluyendo los contenidos y los servicios puestos a disposición de los usuarios en y/o a través de la página web, bien por Red Voucher, bien por sus usuarios, bien por terceros. No obstante, el acceso y la utilización de ciertos contenidos y/o servicios puede encontrarse sometido a determinadas condiciones específicas.</p>
                        <p>Red Voucher se reserva el derecho de modificar en cualquier momento los términos y condiciones de la página web. Se recomienda al usuario consultarlos periódicamente con la finalidad de conocer cualquier modificación o actualización que se haga a los mismos.</p>
                        <p>El usuario deberá respetar y cumplir en todo momento los términos y condiciones aquí establecidos por lo que de forma expresa manifiesta que utilizará el portal de forma responsable y diligente, asumiendo cualquier responsabilidad que pudiera derivarse del incumplimiento de los términos y condiciones o de la legislación aplicable. El contenido de este sitio, sólo debe ser usado como un recurso de publicidad e información y, en su caso, de la activación, administración y control de los folios electrónicos comercializados por Red Voucher o sus empresas subsidiarias, filiales o asociadas.</p>
                        <p>Red Voucher señala, respecto a la comercialización de folios electrónicos que, (i) los folios electrónicos sólo serán válidos en la República Mexicana; (ii) en caso de pérdida, robo o extravío de los folios electrónicos, Red Voucher no se hace responsable bajo ningún motivo del valor en dinero de los mismos, de la reposición, ni de la transferencia de saldos, así como de su mal uso; (iii) no se podrá canjear el saldo de los folios electrónicos por dinero en efectivo, transferir o acumular el saldo a cuentas de crédito, débito, departamentales, o de cualquier otra naturaleza; (iv) los folios electrónicos no se podrán utilizar para comprar ningún otro producto o servicio diferente al señalado en los mismos; (v) en caso de vencimiento de los folios electrónicos, estos ya no podrán ser redimidos ni reembolsados. De la misma forma, los folios electrónicos dejarán de tener valor alguno una vez que se haya hecho uso de los mismos durante el periodo de vigencia.</p>
                        <p>Queda prohibido utilizar la página web para transmitir, almacenar, divulgar, promover y/o distribuir datos o contenidos que sean portadores de virus o códigos informáticos, archivos o programas cuya finalidad sea interrumpir, destruir, perjudicar o de cualquier manera generar un efecto nocivo en el funcionamiento de cualquier programa, sistema o equipo informático o de telecomunicaciones.</p>
                        <p>El usuario conoce y acepta que Red Voucher no otorga ninguna garantía de cualquier naturaleza, ya sea expresa o implícita, sobre los datos, contenidos, información y servicios que se incorporan y ofrecen desde el mismo. Exceptuando los casos en que la legislación aplicable imponga expresamente lo contrario, y exclusivamente con la medida y extensión en que lo imponga, Red Voucher no garantiza ni asume responsabilidad alguna respecto a los posibles daños y perjuicios causados por el uso y utilización de la información, datos y servicios de la página web. En todo caso, Red Voucher no asume responsabilidad alguna por los daños y perjuicios que puedan deberse a la información y/o servicios prestados o suministrados por terceros diferentes de Red Voucher. Toda responsabilidad será del tercero, ya sea proveedor, canal de venta o colaborador.</p>
                        <p>Todos los contenidos, marcas, logos, dibujos, documentación, programas informáticos o cualquier otro elemento susceptible de protección por la legislación de propiedad intelectual o industrial, que sean accesibles en la página web corresponden exclusivamente a Red Voucher o a sus legítimos titulares y quedan expresamente reservados todos los derechos sobre los mismos. Queda expresamente prohibida la creación de enlaces de hipertexto (links) a cualquier elemento integrante de la página web sin la autorización de Red Voucher, siempre que no sean a una página web que no requiera identificación o autenticación para su acceso, o el mismo esté restringido.</p>
                        <p>En cualquier caso, Red Voucher se reserva todos los derechos sobre los contenidos, información datos y servicios que ostente sobre los mismos. Red Voucher no concede licencia o autorización de uso alguna al usuario sobre sus contenidos, datos o servicios, distinta de la que expresamente se detalle en las presentes condiciones generales de uso de la página web.</p>
                        <p>Las operaciones que pueden realizarse a través de la página web de Red Voucher y/o sus empresas subsidiarias o afiliadas se regirán por los términos y condiciones establecidos por la legislación mercantil y por la Ley Federal de Protección al Consumidor, vigentes en la República Mexicana.</p>
                        <p>Las presentes condiciones se rigen y se interpretan de acuerdo con las Leyes de la República Mexicana. Para cualquier reclamación serán competentes los juzgados y tribunales de la Ciudad de México.</p>
                        <p>Todas las notificaciones, requerimientos, peticiones y otras comunicaciones que el usuario desee efectuar a Red Voucher, titular del Portal, deberán realizarse por escrito y se entenderá que han sido correctamente realizadas cuando hayan sido recibidas en la dirección ubicada en la calle Texas 38, Colonia Nápoles, Delegación Benito Juárez, C.P.03810, Ciudad de México, o bien, al siguiente correo electrónico: lvillavicencio@redcompanies.com.mx.</p>

                        <h6>POLÍTICA DE PRIVACIDAD DE RED VOUCHER</h6>
                        <p>1. Información que obtenemos. Recopilamos datos personales, proporcionados directamente por el Usuario de la página web, tales como: nombre completo, teléfono fijo o móvil y correo electrónico; tipo de tarjeta bancaria (Visa o Mastercard), nombre del titular de la tarjeta, número de tarjeta, fecha de expiración y código de seguridad.</p>
                        <p>2. Uso de tu información. La información se tratará para los fines señalados en el Aviso de Privacidad, que son: identificación, contacto con cliente, operación, administración, facturación, comercialización y otros fines análogos (mercadotécnicos, publicitarios o de prospección comercial), relacionados con los servicios que presta Red Voucher.</p>
                        <p>a. Compartirla con terceros. Cuando Red Voucher deba compartir información con terceros por razón de una relación contractual encaminada a mejorar los servicios que presta, compartirá la información personal necesaria para estos fines.</p>
                        <p>b. Red Voucher compartirá la información cuando así deba hacerlo por virtud de un mandato judicial o de ley; por una exigencia de salvaguarda de un interés público o para la procuración o administración de justicia</p>
                        <p>3. Seguridad y resguardo de la información. Red Voucher se asegura de contar con la Protección y Seguridad necesarias, mediante medidas administrativas, técnicas, legales y físicas para el tratamiento que se le dé a la información proporcionada por el Usuario de la página. Sin embargo, no podemos garantizar que terceros no autorizados no alteren o violen las medidas de seguridad y/o que usen la información para fines inapropiados. El Titular de los datos, reconoce que proporcionas su información personal bajo su propio riesgo.</p>
                        <p>Red Voucher se reserva el derecho a modificar y actualizar cada cierto tiempo estos Términos y Condiciones y la Política de Privacidad. Cuando esto suceda, lo hará saber de forma oportuna y lo publicarán en un lugar donde sea de fácil acceso para el Titular.</p>
                        <p>En caso de preguntas y/o comentarios sobre estos Términos o sobre cualquier otro asunto relacionado con la página web de Red Voucher, se puede contactar a la empresa en el siguiente correo electrónico: diego.montero@bufetebellavista.com</p>


                        <h6>POLÍTICAS DE USO Y DEVOLUCIONES</h6>
                        <p>Los usuarios que adquieran algún servicio a través de redvoucheronline.com deberán de realizar el pago exclusivamente con tarjetas de crédito / debito bancaria (Visa, Mastercard) o con efectivo.</p>
                        <p>La devolución realizada de un pago en efectivo será través de Wester Union o por medio de una transferencia bancaria a la cuenta indicada por el cliente.</p>
                        <p>Redvoucheronline.com solo acepta cambios cuando el folio de redención no sea válido en taquilla de cualquier Cinépolis en México.</p>
                        <p>El cliente deberá enviar un mail con el número de FOLIO en el “asunto” a hola@somosredcompanies.com, se validara que este FOLIO fue erróneo y NO redimido en ninguna taquilla de Cinépolis y se otorgara uno nuevo con las mismas condiciones en un lapso no mayor a 48 horas.</p>

                    </div>

                </div>
            </section>
        );
    }
}
