import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import "./cardRegistrationItem.scss";
import swal from "sweetalert";

import { verificationCardService } from '../../services/verificationCard.service';
import { subscriptionService } from "../../services/subscription.service";

export const CardRegistrationItem = ({ history, selectCard, isRedpay, currentPurchase }) => {
    const user = useSelector((state) => state.user);
    const [cardList, setCardList] = useState([]);
    const [card, setCard] = useState(null);

    useEffect(() => {
        getList();
    }, [user]);

    const getList = () => {
        verificationCardService.getList()
            .then(result => {
                setCardList(result);
            });
    }

    const newCard = () => {
        //history.push("/card-registration");
        history.push({
            pathname: '/card-registration',
            state: { model: currentPurchase }
        })
    }

    const onSelectCard = (item) => {
        setCard(item);
        if (selectCard != undefined)
            selectCard(item);
    }

    const CancelSubscription = () => {
        swal({
            title: "Confirmar",
            text: "¿Estas seguro que deseas cancelar tu suscripción de la cominidad RedGodinez?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    subscriptionService.cancel()
                        .then(response => {
                            swal({ text: response.Message, icon: "info" });
                        });
                }
            });

    }

    const deleteCard = (item) => {
        swal({
            title: "¿Estás seguro que deseas eliminar esta tarjeta?",
            text: item.CardNumber,
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, eliminar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    verificationCardService.deleteCard({ VerifiedCardId: item.VerifiedCardId })
                        .then(function (response) {
                            if (response.Code == 200) {
                                getList();
                                swal({ text: response.Message, icon: "success" });
                            } else {
                                swal({ text: response.Message, icon: "error" });
                            }
                        });
                }
            });
    }

    return (
        <section className="wrapper_item_card_register wrapper_other container-fluid">
            <h1>Mis tarjetas</h1>
            <div className='row mb-3 mt-1'>
                {cardList.map(item =>
                    <div className='col-lg-3 col-md-4 col-sm-6 mb-3' key={item.VerifiedCardId}>
                        <div className={`item_card_register ${card && (card.VerifiedCardId == item.VerifiedCardId && 'is_select-xxxxxxxxx')}`}>
                            <span className='ico_delete' onClick={() => deleteCard(item)}><i className="fas fa-minus-circle"></i></span>
                            <span className='spanico'><i className="fas fa-dollar-sign"></i></span>
                            <div className='content_item' onClick={() => onSelectCard(item)}>
                                <p className='imgico'>
                                    {
                                        {
                                            '001': <img src="./imgs/visa.jpg" alt="tarjetas visa, red godinez" />,
                                            '002': <img src="./imgs/masterc.jpg" alt="tarjetas master card, red godinez" />
                                        }[item.CardType]
                                    }
                                </p>

                                <h1 className='mb-1 fw400 text-right'>{item.CardNumber}</h1>
                                <p className='mb-0 fw400 pvig text-right'>{item.FirstName} {item.LastName}</p>
                            </div>
                        </div>
                    </div>
                )
                }
                <div className='col-lg-3 col-md-4 col-sm-6 mb-3' onClick={newCard}>
                    <div className='item_card_register card_more'>
                        <div className='content_item text-center'>
                            <span className='icomore'><i className="fas fa-plus"></i></span>
                            <p className='mb-0 pvig fw400'>Tarjeta nueva</p>
                        </div>
                    </div>
                </div>
            </div>
            {isRedpay ? '' :
                user.activeSubscription ? (
                    <div>
                        <div className="row col-6">
                            <h3>Mi suscripción</h3>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label>Membresía Red Godínez 1 Mes</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {user.showCancelSubscriptionButton ? <button type="button" className="btn mb-2" onClick={CancelSubscription}>Cancelar Suscripción</button> : ""}
                        </div>
                    </div>) : ""}
        </section >
    )
}