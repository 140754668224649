import React from 'react'
import './purchasedItemDetails.scss'

const PurchasedItemDetails = (props) => {

    return (
        <div className="content_item_shopping">
            <div className="row align-items-center">
                <div className="col-md-3">
                    <span className="bg_logo_shop">
                        <img src={props.Logo} alt="" />
                    </span>
                </div>
                <div className="col-md-9">
                    <p className="item_purchased box_flex">
                        <span>({props.Quantity})</span>
                        <span>{props.Description}</span>
                        <span>${props.SubTotal}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PurchasedItemDetails;