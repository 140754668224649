import React, { Component } from "react";
import { RedPay } from "../components/paymentMethod/RedPay/redpay";

export class PaymentMethod extends Component {

    switchPaymentMethod = () => {
        const { model } = this.props.location.state;
        let component
        switch (model.PaymentMethodId) {
            // Red Pay
            case 11:
                component = (<RedPay {...model} history={this.props.history}></ RedPay>)
                break
        }
        return component;
    }

    render() {
        return (
            <section className="container_payment apm">
                {this.switchPaymentMethod()}
            </section>
        )
    }
}