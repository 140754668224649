import React, { Component } from 'react';
import {Link} from 'react-router-dom';
 import { setModal } from '../../store/actions/user.actions';
import { connect } from 'react-redux';
import { metaPixelService } from '../../services/metaPixel.service';

import './hubItemProvider.scss'

 class HubItemProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providersOptions: props.providers,
        }
    }

    validateSetModal = (to) => {
        if (!this.props.isAuthenticated) this.props.setModal();
        else window.location.href = to;
      };

    componentWillReceiveProps(next_props) {
        this.setState({ providersOptions: next_props.providers });
    }


    processRedirection = (id, type ) => {  
        let route =  "/provider?id=" + id+ "?type=" + type ;
        if (this.props.isAuthenticated || type === 'coupons') window.location.href = route;
        this.props.setModal();
    }


    render() {
        const { providersOptions } = this.state;
        return (

            <div className="box_container_hub" >
                {providersOptions.map(providersOption => {
                    return (
                        <>
                        <a  
                            className="item_provaider_hub"
                            key={providersOption.id}
                            onClick={()=>{
                                this.processRedirection(providersOption.Id, providersOption.Type);
                            }}
                        >
                            <div className="item__hub">
                                <img className='img_provider' src={providersOption.IsAvailable ? providersOption.ProviderLogo : providersOption.ImageNotAvailable} alt="Descuentos y promociones en Red Godinez" />
                            </div>
                        </a>
                        </>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { isAuthenticated: state.user.isAuthenticated };
  };
  
  export default connect(mapStateToProps, {setModal})(HubItemProvider);