import React from 'react';

import {Button, Modal, ModalBody} from "reactstrap";

export const ModalSuccess = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="sm" centered={true} backdrop="static">
                <ModalBody>
                    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                        <svg className="checkmark" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                        <h3 className="text-center mt-3">{props.title ? props.title : ''}</h3>
                        <p className="text-center">{props.description ? props.description : ''}</p>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <Button size="lg" color="success" onClick={props.toggle}>OK</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
    );
}
