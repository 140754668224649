import React, { useState, useEffect } from 'react';
import ItemPurchase from '../itemPurchase'
import BoxPayMethod from '../boxPayMethod'
import './asidePurchase.scss'
import swal from "sweetalert"
import { cartHook } from "../../hooks/cart.hook";

const AsidePurchase = ({ activeAside, onCloseClick, toggleBlocking }) => {

    const [pruchaseItems, setPurchaseItems] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (activeAside) {
            setPurchaseItems(JSON.parse(localStorage["pruchaseList"]));
            setTotal(JSON.parse(localStorage["pruchaseTotal"]));
        }
    }, [activeAside]);

    const onChangeQuantity = (providerId, productId, value) => {
        cartHook.onChangeQuantity(providerId, productId, parseInt(value))
            .then(function () {
                setTotal(JSON.parse(localStorage["pruchaseTotal"]));
                setPurchaseItems(JSON.parse(localStorage["pruchaseList"]));
            });
    }

    const deleteItem = (providerId, providerName) => {
        swal({
            title: "¿Estas seguro?",
            text: "¡Desea eliminar los productos de " + providerName + " del carrito de compras!",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, eliminar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    cartHook.deleteItem(providerId)
                        .then(function () {
                            setTotal(JSON.parse(localStorage["pruchaseTotal"]));
                            setPurchaseItems(JSON.parse(localStorage["pruchaseList"]));
                        });
                }
            });
    }

    const deleteItemDetail = (providerId, productId, reference) => {
        swal({
            title: "¿Estas seguro?",
            text: "¡Desea eliminar este productos del carrito de compras!",
            icon: "warning",
            buttons: ["Cancelar", "Si, eliminar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    cartHook.deleteItemDetail(providerId, productId, reference)
                        .then(function () {
                            setTotal(JSON.parse(localStorage["pruchaseTotal"]));
                            setPurchaseItems(JSON.parse(localStorage["pruchaseList"]));
                        });
                }
            });
    }
    return <React.Fragment>
        <aside className={`wrapper_aside_purchase trans ${activeAside}`}>
            <div className="box_content_aside">
                <div className="titulares box_flex ">
                    <h5 className="ttls_sections fw600 m-0"><i class="fas fa-shopping-basket"></i> Mi mochila</h5>
                    <div className=" btn_closeaside" onClick={onCloseClick}><i className="fas fa-times-circle"></i> <span>Cerrar</span></div>
                </div>
                <div className="content_items">
                    {
                        pruchaseItems.map(item => {
                            return <ItemPurchase {...item} deletePurchaseItem={deleteItem} deletePurchaseItemDetail={deleteItemDetail} onChangeQuantity={onChangeQuantity} />
                        })
                    }
                </div>
                <div className="box_footer_aside ttls_sections">
                    <div className="box_total_neto">
                        <div>
                            <h5 className="fw600 c_primary m-0">¡FELICIDADES!</h5>
                            <p className="label fw300 mb-1">En esta compra ahorraste más del 35% del costo normal.</p>
                        </div>
                        <div className="box_w">
                            <h4 className=" fw700">Total: ${(total).toLocaleString("es-mx", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</h4>
                        </div>
                    </div>
                    <div className="wrapper_paymethod">
                        <div>
                            <h6 className="ttls_sections"><span>Elige tu método de pago</span></h6>
                            <BoxPayMethod onCloseClick={onCloseClick} toggleBlocking={toggleBlocking} />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <div className="bg_pleca trans" onClick={onCloseClick}></div>
    </React.Fragment>
}

export default AsidePurchase
