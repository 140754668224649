import React from "react";
import swal from "sweetalert";
import { BasePaymentMethod } from "../BasePaymentMethod";
import InputMask from "react-input-mask";
import { CardRegistrationItem } from '../../cardRegistrationItem/cardRegistrationItem';
import { countrySerice } from "../../../services/country.service";
import "./redpay.scss";
import { Card } from "reactstrap";

export class RedPay extends BasePaymentMethod {
    constructor(props) {
        super(props)
        this.state = {
            currentPurchase: {},
            isInProcess: false,
            CardExpirationMonth: "",
            CardExpirationYear: "",
            Cvv: "",
            Countrys: [],
            States: [],
            CountryId: "",
            IdState: "",
            PostalCode: "",
            City: "",
            Street: "",
            StreetNumber: "",
            Card: null,
            formValidators: {
                CardExpirationMonth: "",
                CardExpirationYear: "",
                Cvv: "",
                PostalCode: "",
                City: "",
                Street: "",
                StreetNumber: ""
            }
        }
    }

    componentWillMount() {
        this.getCountry();
        let purchaseItem = {
            Email: this.props.Email,
            OrderId: this.props.OrderId,
            OrderNumber: this.props.OrderNumber,
            Amount: this.props.Amount,
            PaymentMethodId: this.props.PaymentMethodId,
            UrlSubmit: this.props.UrlSubmit
        }
        this.setState({ currentPurchase:purchaseItem});
    }

    getCountry = () => {
        countrySerice.getAllCountry()
            .then(result => {
                this.setState({ Countrys: result });
            });
    }

    changeCountry = (event) => {
        this.setState({ CountryId: event.target.value });
        countrySerice.getState(event.target.value)
            .then(result => {
                this.setState({ States: result });
            });
    }

    changeState = (event) => {
        this.setState({ IdState: event.target.value });
    }

    selectCard = (item) => {
        this.setState({ Card: item });
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({ [name]: value })
        await this.validateInputs(event.target)
    }

    validateInputs = async (input) => {
        const name = input.name
        const value = input.value
        const formValidators = this.state.formValidators;
        switch (name) {
            case "PostalCode":
            case "City":
            case "Street":
            case "StreetNumber":
            case "Cvv":
                formValidators[name] = value.length <= 0 ? "Éste campo es obligatorio" : ""
                break;
            case "CardExpirationYear":
                formValidators[name] = (value.length != 4 && !this.state.Card.HasExternalToke) ? "El año debe contener 4 dígitos" : ""
                break;
            case "CardExpirationMonth":
                formValidators[name] = (value.length != 2 && !this.state.Card.HasExternalToke) ? "El mes debe contener 2 dígitos" : ""
                break;
        }
        await this.setState({ formValidators })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.Card != null) {
            const inputs = document.forms['formulario-pago'].getElementsByTagName("input");
            const validationInputs = Object.keys(this.state.formValidators)
            for (let input of inputs) {
                if (validationInputs.includes(input.name)) {
                    this.validateInputs(input);
                }
            }
            let failedInputs;
            failedInputs = Object.values(this.state.formValidators).find(value => {
                return value == null || value.length > 0
            })
            if (failedInputs === undefined) {
                if (this.state.Card.HasExternalToken || (this.state.CountryId.length > 0 && this.state.IdState.length > 0)) {
                    this.setState({ isInProcess: true });
                    document.getElementById('formulario-pago').submit();
                }
                else
                    swal({ text: "El país y estado son requeridos.", icon: "error" });
            }

        } else {
            swal({ text: "Debes seleccionar una tarjeta para continuar", icon: "error" });
        }
    }

    ButtonProcess = () => {
        return !this.state.isInProcess ?
            <><button onClick={this.handleSubmit}>Pagar <strong>${this.props.Amount} MXN</strong></button>
                <div className="cancel-button-content">
                    <button type="button" onClick={this.cancel}>Cancelar</button>
                </div></> :
            <button type="button" className="btn btn-primary" disabled>En proceso</button>
    }

    render() {
        const { formValidators } = this.state;
        return (
            <form action={this.props.UrlSubmit} method="POST" id="formulario-pago">
                <input type="text" className="hidden" value={this.props.OrderId} name="OrderId" readOnly></input>
                <input type="text" className="hidden" value={this.state.Card && this.state.Card.VerifiedCardId} name="VerifiedCardId" readOnly></input>
                <section className="wrapper-banorte container-fluid">
                    <CardRegistrationItem history={this.props.history} selectCard={this.selectCard} isRedpay={true} currentPurchase={this.state.currentPurchase} />
                    <div className='row'>
                        <div className='col-sm-5'>
                            <h1>Datos del comprador</h1>
                            <div className="data-side">
                                <div className="data-content">
                                    <label className='mb-2'>Comprador</label>
                                    <div>
                                        <p className='pmail font-italic fw300'><i className="fas fa-envelope"></i> {this.props.Email}</p>
                                    </div>
                                    <div>
                                        <label>Número de pedido</label>
                                        <p className="data-p mb-3">{this.props.OrderNumber}</p>

                                        <label>Monto</label>
                                        <p className="amount-p">${this.props.Amount} MXN</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.state.Card &&
                            <div className='col-sm-7'>
                                <h1>Pago con tarjeta</h1>
                                <div className="payment-side">
                                    <div className="payment-content">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <p>Nombre</p>
                                                <input type="text"
                                                    className='payment-card' value={this.state.Card.FirstName} disabled />
                                            </div>
                                            <div className='col-md-6'>
                                                <p>Apellido</p>
                                                <input type="text"
                                                    className='payment-card' value={this.state.Card.LastName} disabled />
                                            </div>
                                        </div>
                                        <p>Número de tarjeta</p>
                                        <input className='payment-card' value={this.state.Card.CardNumber} disabled />
                                        {!this.state.Card.HasExternalToken &&
                                            <div className='row align-items-end'>
                                                <div className='col-6 col-md-4'>
                                                    <p>Fecha de expiración</p>
                                                    <InputMask
                                                        className={`payment-date ${formValidators.CardExpirationMonth && 'error'}`}
                                                        placeholder="MM"
                                                        mask="99"
                                                        name="CardExpirationMonth"
                                                        maskChar=""
                                                        onChange={this.handleChange} />
                                                    {(formValidators.CardExpirationMonth) && (
                                                        <small>{formValidators.CardExpirationMonth}</small>
                                                    )}
                                                </div>
                                                <div className='col-6 col-md-4'>
                                                    <InputMask
                                                        className={`payment-date ${formValidators.CardExpirationYear && 'error'}`}
                                                        placeholder="AAAA"
                                                        mask="9999"
                                                        name="CardExpirationYear"
                                                        maskChar=""
                                                        onChange={this.handleChange} />

                                                    {(formValidators.CardExpirationYear) && (
                                                        <small>{formValidators.CardExpirationYear}</small>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        <div className='row'>
                                            <div className='col-6 col-md-4'>
                                                <p>Código de seguridad</p>
                                                <InputMask
                                                    className={`payment-code ${formValidators.Cvv && 'error'}`}
                                                    mask="999"
                                                    name="Cvv"
                                                    maskChar=""
                                                    onChange={this.handleChange} />
                                                {(formValidators.Cvv) && (
                                                    <small>{formValidators.Cvv}</small>
                                                )}
                                            </div>
                                            <div className='col-6 col-md-4'>
                                                <span><i className="fa fa-credit-card card-i"></i></span>
                                                <span className="card-text">3 dígitos al reverso de la tarjeta</span>
                                            </div>
                                        </div>
                                        {!this.state.Card.HasExternalToken &&
                                            <>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p>Dirección</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Código postal</p>
                                                        <input type="number"
                                                            maxLength={5}
                                                            className={`payment-card ${formValidators.PostalCode && 'error'}`}
                                                            name="PostalCode"
                                                            value={this.state.PostalCode}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.PostalCode) && (
                                                            <small>{formValidators.PostalCode}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p>País</p>
                                                        <select className={`payment-card ${formValidators.CountryId && 'error'}`} onChange={this.changeCountry} name="CountryId">
                                                            <option selected>Selecciona una opción</option>
                                                            {
                                                                this.state.Countrys.length > 0 ?
                                                                    this.state.Countrys.map(item => <option key={item.CountryName} value={item.CountryId}>{item.CountryName}</option>) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p>Estado</p>
                                                        <select className={`payment-card ${formValidators.StateId && 'error'}`} onChange={this.changeState} name="IdState">
                                                            <option selected>Selecciona una opción</option>
                                                            {
                                                                this.state.States.length > 0 ?
                                                                    this.state.States.map(item => <option key={item.Name} value={item.IdState}>{item.Name}</option>) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Ciudad</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.City && 'error'}`}
                                                            name="City"
                                                            value={this.state.City}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.City) && (
                                                            <small>{formValidators.City}</small>
                                                        )}
                                                    </div>
                                                    <div className="col-6">
                                                        <p>Calle</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.Street && 'error'}`}
                                                            name="Street"
                                                            value={this.state.Street}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.Street) && (
                                                            <small>{formValidators.Street}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Núm. exterior</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.StreetNumber && 'error'}`}
                                                            name="StreetNumber"
                                                            value={this.state.StreetNumber}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.StreetNumber) && (
                                                            <small>{formValidators.StreetNumber}</small>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {this.ButtonProcess()}
                                    </div>
                                    <div className="payment-img-content">
                                        <img src="./imgs/paymentMethod/visa-and-mastercard-logo.png" alt="VisaMastercard" />
                                    </div>
                                </div>
                            </div>}
                        <div className="information-side col-sm-12">
                            <div className="information-content">
                                <label className="information-title">Su información está protegida</label>
                                <p className="information-text">
                                    Empleamos los más altos estándares de seguridad (SSL, 3D Secure) para proteger su información personal y la de su tarjeta. <br />
                                    Recuerda que en tu tarjeta de crédito o estado de cuenta quedará registrada la transacción a nombre de RV*REDACCESS.
                                </p>
                            </div>
                        </div>
                    </div>

                </section>
            </form >
        )
    }
}
