import React, {useEffect, useState} from 'react';
import FavoriteCheck from '../favoriteCheck';

import './providers.scss';
import {ProvidersRecharges} from "./providersRecharge";
import {ProvidersCoupons} from "./providersCoupons";
import {ProvidersServices} from "./providersServices";
import {Button, Col, Row} from "reactstrap";
import { useSelector } from 'react-redux';

export const Providers = (props) => {
    const displayName = Providers.name;
    const user = useSelector((state) => state.user);

    //params
    const [params, setParams] = useState(props.providerId.split('?'));
    const [model, setModel] = useState('');

    const providerID = params[1].slice(3);
    const providerType = params[2].slice(5);

    useEffect(() => {
        // return () => 
        providerModel();
    }, [])
    

    const providerModel = () => {
        fetch("/api/Products/Index?Id=" + providerID, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                if (result.Status)
                    setModel(result.Model)
            })
            .catch(error => console.log('error', error));
    }

    const style = {
        bgProviders: { background: model.UrlHeader }
    }

    const goHome = () => window.location.href = '/'

    return (
        <div className="wrapper_providers">
            <div className="bg_providers" style={style.bgProviders}></div>
            <FavoriteCheck />

            {
                (providerType === 'recharge') ?
                    <ProvidersRecharges
                        providerId={providerID}
                        model={model}
                        type = {providerType}
                        paymentMethods={user.paymentMethods}
                        onCloseClick={props.onCloseClick}
                    />
                : (providerType === 'services') ?
                    <ProvidersServices
                        providerId={providerID}
                        model={model}
                        type = {providerType}
                        paymentMethods={user.paymentMethods}
                    />
                : (providerType === 'coupons') ?
                    <ProvidersCoupons
                        providerId={providerID}
                        model={model}
                        type = {providerType}
                    />
                :
                    <Row>
                        <Col xs={12}>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <h1 className="text-center">¡Ups! nada para mostrar</h1>
                                <Button color="danger" onClick={goHome} className="mt-5">Home</Button>
                            </div>
                        </Col>
                    </Row>
            }

        </div>
    );
}

