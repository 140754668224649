import $ from 'jquery'
import swal from "sweetalert"

export const cartHook = {
    addItem,
    onChangeQuantity,
    deleteItem,
    deleteItemDetail,
    getItems,
    clean
}

async function addItem(product) {
    let cartItems = JSON.parse(localStorage["pruchaseList"])
    let totalItems = parseFloat(localStorage["pruchaseTotal"]);

    let item = {
        ProductId: product.ProductId,
        Name: product.Name,
        Price: product.ProductPrice,
        Quantity: 1,
        Code: product.ProductCode,
        UrlImage: product.urlLogoClrs,
        Descripcion: product.Description,
        ProviderId: product.proveedorId,
        Total: product.ProductPrice,
        Reference: product.Reference
    }

    let foundProvider = cartItems.find(p => p.ProviderId == product.proveedorId);
    if (foundProvider != undefined) {
        let isNew = true;
        if (item.Reference == undefined) {
            let foundProduct = foundProvider.Items.find(p => p.ProductId == item.ProductId);
            if (foundProduct != undefined) {
                foundProduct.Quantity += item.Quantity;
                foundProduct.Total = foundProduct.Quantity * item.Price
                isNew = false;
            }
        } else {
            let foundProduct = foundProvider.Items.find(p => p.ProductId == item.ProductId && p.Reference == item.Reference);
            if (foundProduct != undefined) {
                //swal({ text: "Esta referencia ya esta en carrito de compras", icon: "error" });
                return false;
            }
        }

        if (isNew) foundProvider.Items.push(item)

        foundProvider.Total += item.Total;
    } else {
        let itemProvider =
        {
            ProviderId: product.proveedorId,
            ProviderName: product.providerName,
            Logo: product.urlLogoClrs,
            Total: item.Price,
            Items: [item]
        }
        cartItems.push(itemProvider);
        $("#spanActivePurchase").click();
    }

    localStorage["pruchaseTotal"] = totalItems + item.Price
    localStorage["pruchaseList"] = JSON.stringify(cartItems)
}

async function onChangeQuantity(providerId, productId, value) {
    let cart = JSON.parse(localStorage["pruchaseList"]);
    let total = parseFloat(localStorage["pruchaseTotal"]);

    let foundProvider = cart.find(p => p.ProviderId == providerId);
    if (foundProvider != undefined) {
        let foundProduct = foundProvider.Items.find(p => p.ProductId == productId);
        if (foundProduct != undefined) {
            foundProvider.Total -= foundProduct.Total;
            total -= foundProduct.Total;

            foundProduct.Quantity = value;
            foundProduct.Total = foundProduct.Quantity * foundProduct.Price;

            foundProvider.Total += foundProduct.Total;
            total += foundProduct.Total;
        }
    }

    localStorage["pruchaseTotal"] = total;
    localStorage["pruchaseList"] = JSON.stringify(cart);
}

async function deleteItem(providerId) {
    let cart = JSON.parse(localStorage["pruchaseList"]);
    let total = parseFloat(localStorage["pruchaseTotal"]);

    let foundProvider = cart.find(p => p.ProviderId == providerId);
    if (foundProvider != undefined) {

        total -= foundProvider.Total;
        cart = cart.filter(p => p.ProviderId != providerId);
    }

    localStorage["pruchaseTotal"] = total;
    localStorage["pruchaseList"] = JSON.stringify(cart);
}

async function deleteItemDetail(providerId, productId, reference) {
    let cart = JSON.parse(localStorage["pruchaseList"]);
    let total = parseFloat(localStorage["pruchaseTotal"]);

    let foundProvider = cart.find(p => p.ProviderId == providerId);
    if (foundProvider != undefined) {
        let foundProduct = foundProvider.Items.find(p => p.ProductId == productId);
        if (foundProduct != undefined) {
            foundProvider.Total -= foundProduct.Total;
            total -= foundProduct.Total;
            if (reference == undefined) foundProvider.Items = foundProvider.Items.filter(p => p.ProductId != productId);
            else foundProvider.Items = foundProvider.Items.filter(p => p.Reference != reference)

            if (foundProvider.Total == 0) cart = cart.filter(p => p.ProviderId != providerId);
        }
    }

    localStorage["pruchaseTotal"] = total;
    localStorage["pruchaseList"] = JSON.stringify(cart);
}

function getItems(rechargeProducts) {
    let products = [];

    if (!rechargeProducts) {
        let cartItems = JSON.parse(localStorage["pruchaseList"])
        cartItems.map(provider => {
            provider.Items.map(itemtemp => {
                let item = {
                    ProductId: itemtemp.ProductId,
                    UrlImage: itemtemp.UrlImage,
                    Name: itemtemp.Name,
                    ProductPrice: itemtemp.Price,
                    Cant: itemtemp.Quantity,
                    ProductCode: itemtemp.Code,
                    Description: itemtemp.Descripcion,
                    ProviderId: itemtemp.ProviderId
                };
                products.push(item);
            })
        });
    }
    else {
        rechargeProducts.reference.isSaveReference = rechargeProducts.reference.Name != "";
        products.push(rechargeProducts)
    }

    let subscriptionProductsId = [1, 2];
    let subscriptionProducts = products.filter(a => subscriptionProductsId.includes(a.ProductId)) ?? [];

    if (subscriptionProducts.length > 0) {
        localStorage["rg_pending_products"] = localStorage["pruchaseList"]
        products = subscriptionProducts;
    }

    return products;
}

async function clean() {
    localStorage["pruchaseTotal"] = "0";
    localStorage["pruchaseList"] = "[]";
}