import { callApi } from './service'

export const subscriptionService ={
    cancel
}

async function cancel() {
    return callApi("/api/PaymentMethod/CancelSubscription/","post")
    .then((result)=> {
        return result;
    })
}