import React, { useState } from "react";
import swal from "sweetalert";
import "./formEmbassador.scss";

export const FormEmbassador = () => {
    const [form, setForm] = useState(null);
    const [activeSec, setActiveSec] = useState("1");

    const toggle = (sec) => {
        if (activeSec !== sec) setActiveSec(sec);
    };

    const handleOnChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (activeSec == 1) toggle('2');
        else if (activeSec == 2) {
            fetch("/api/Account/RegisterAmbassador", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            })
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(function (result) {
                    if (result.Status) {
                        swal({ text: result.Message, icon: "success" });
                        toggle('3');
                    } else {
                        swal({ text: result.Message, icon: "error" });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    swal({ text: "Error", icon: "error" });
                });
        }
    };


    return (
        <section className="wrapper_form_embassador" id="#embajadores">
            <form onSubmit={onSubmit}>
                <div className="content_form_embassador">
                    <div>
                        <h2 className="ttls fw700 m-0">
                            <span className="c_primary">Conviértete en un Godín Alfa</span>
                        </h2>
                        <h5>Si ya debes la quincena, te echamos la mano.</h5>
                    </div>

                    <div className="drawer_embassador">
                        <div className="row d-flex flex-column-reverse flex-lg-row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="box_form_embassador">
                                    <p className="fw300 mb-4">
                                        Tráenos a tus colegas y por cada uno, nos mochamos con el 15% de cada referido
                                    </p>
                                    <div className="wraper_steps ">
                                        {{
                                            1: (
                                                <div className="row box_step one">
                                                    <div className="rowdots_step">
                                                        <span className="col_dots is_ok">1 </span>
                                                        <span className="col_dots ">2 </span>
                                                        <span className="col_dots">3 </span>
                                                    </div>
                                                    <div className="col-12">
                                                        <h6>Datos Personales</h6>
                                                    </div>
                                                    <div className=" col-md-6">
                                                        <div className="form-group">
                                                            <label for="Name">Nombre completo</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Name"
                                                                id="Name"
                                                                value={form && form.Name}
                                                                onChange={handleOnChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-6">
                                                        <div className="form-group box_nickname">
                                                            <div className="box_tooltip_form">
                                                                <span className="ico_tool">
                                                                    <i className="fas fa-exclamation-circle"></i>
                                                                </span>
                                                                <span className="info_tool">
                                                                    Este es el nombre corto con el que te
                                                                    identificarán las personas que recomiendes, no
                                                                    olvides decirles que lo seleccionen al
                                                                    registrarse{" "}
                                                                </span>
                                                            </div>
                                                            <label for="Nickname">Tu Apodo</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Nickname"
                                                                id="Nickname"
                                                                value={form && form.Nickname}
                                                                onChange={handleOnChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="Email">Correo electrónico</label>
                                                            <input
                                                                type="Email"
                                                                className="form-control"
                                                                name="Email"
                                                                id="Email"
                                                                value={form && form.Email}
                                                                onChange={handleOnChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="PhoneNumber">Teléfono</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="PhoneNumber"
                                                                id="PhoneNumber"
                                                                value={form && form.PhoneNumber}
                                                                onChange={handleOnChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="text-center mt-4">
                                                            <button type="submit" className="btn" >Siguiente</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                            2: (
                                                <div className="row box_step two">
                                                    <div className="rowdots_step">
                                                        <span className="col_dots ">1 </span>
                                                        <span className="col_dots is_ok ">2 </span>
                                                        <span className="col_dots">3 </span>
                                                    </div>
                                                    <div className="col-12">
                                                        <h6>Datos bancarios</h6>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group">
                                                            <label for="Bank">Banco</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Bank"
                                                                id="Bank"
                                                                value={form && form.Bank || ""}
                                                                onChange={handleOnChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="form-group box_nickname">
                                                            <label for="Clabe">CLABE inter bancaria</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Clabe"
                                                                id="Clabe"
                                                                value={form && form.Clabe}
                                                                onChange={handleOnChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="text-center mt-4">
                                                            <button type="button" className="btn btn-light mr-2 mb-2" onClick={() => toggle('1')}>Regresar</button>
                                                            <button type="submit" className="btn mb-2">
                                                                Quiero ser Godín Lomo Plateado
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                            3: (
                                                <div className="row box_step three">
                                                    <div className="rowdots_step">
                                                        <span className="col_dots ">1 </span>
                                                        <span className="col_dots ">2 </span>
                                                        <span className="col_dots is_ok">3 </span>
                                                    </div>
                                                    <div className=" col-md-12">
                                                        <div className="">
                                                            <h5 className="ttls fw600 text-center">
                                                                Encontrarás aquí todo el material necesario para
                                                                compartir en tus redes sociales
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-12 h-100">
                                                        <div className="h-100">
                                                            <h5 className="ttls fw600 text-center">¡Gracias por registrarte!</h5>
                                                            <p className="text-center">En breve te contactaremos</p>
                                                        </div>
                                                    </div>*/}
                                                    {/*<div className="col-12">
                                                        <div className="text-center mt-3">
                                                            <a href="https://storagervadminprod.blob.core.windows.net/ctnadmincontentmx/RedGodinez/Ambassador/Material.rar" className="btn">Descargar ahora</a>
                                                        </div>
                                                    </div>*/}
                                                </div>
                                            ),
                                        }[activeSec]
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="box_form_img">
                                    <img
                                        src="./imgs/img-form.png"
                                        alt="Red Godinez. Adquiere tus productos a precios increíbles en comparación de los precios comerciales"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
};
