import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import './sliderHome.scss'

export class SliderHome extends Component {
    static displayName = SliderHome.name;
    constructor(props) {
        super(props)
        this.state = {
            slider: props.carrousel
        }
    }

    componentWillReceiveProps(next_props) {
        this.setState({ slider: next_props.carrousel });
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            swipeToSlide: true,
        };

        return (
            <div className="wrapper_slider_home">
                <Slider {...settings}>
                    {
                        this.state.slider.map(item => {
                            if (item.Link.indexOf("http://") == 0 || item.Link.indexOf("https://") == 0) {
                                return <div>
                                    <a rel="nofollow" href={"/api/Home/Hub2?idBanner=" + item.CompanyCarrouselId + "&url=" + item.Link} id="enlace" class="automatic" >
                                        <img className="sld_desktop" src={item.UrlImage} alt="" />
                                        <img className="sld_mobile" src={item.UrlImageResponsive} alt="" />
                                    </a>
                                </div>
                            }

                                return <div>
                                    <a rel="nofollow" href={"/api/Home/Hub2?idBanner=" + item.CompanyCarrouselId + "&url=" + item.Link} id="enlace" class="automatic" >
                                        <img className="sld_desktop" src={item.UrlImage} alt="" />
                                        <img className="sld_mobile" src={item.UrlImageResponsive} alt="" />
                                    </a>

                                </div>

                        })
                    }
                </ Slider>
            </div>
        )
    }
} 
