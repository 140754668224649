import React, { useState } from "react"
import { useForm } from 'react-hook-form'
import swal from "sweetalert"
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getUser } from '../../store/actions/user.actions';

const Login = (props, toggle) => {
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        fetch('/api/Account/Login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            if (response.ok) {
                response.json()
                return window.location.href =  '/';
            }
        }).then(function (result) {
            if (result.Status) {
                sessionStorage.setItem("pixelUserInfo", JSON.stringify( result.userInfo));
                sessionStorage.setItem("userId", JSON.stringify( result.profileId));
                e.target.reset();
                dispatch(getUser());
                props.toggle();
                window.location.href = '/'
            } else {
                swal({ text: result.Message, icon: "error" });
            }
        }).catch(function (error) {
            console.log(error);
            swal({ text: "Error", icon: "error" });
        });
    }

    const [showPass, setShowPass] = useState("password");
    const [showIco, setShowIco] = useState("fa-eye-slash");

    const setInputPass = () => {
        setShowPass(showPass === "password" ? "text" : "password");
        setShowIco(showIco === "fa-eye-slash" ? "fa-eye" : "fa-eye-slash");
    };

    return (
        <div className="content_logeo is_badge login">
            <div className=" htop">
                <h5 className="ttls fw600 text-center text-light mb-4">Iniciar sesión</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="email-user">Correo electrónico</label>
                        <input type="email" className="form-control" name="Email" id="email-user" required ref={register} />
                    </div>
                    <div className="form-group mb-1 p_relat">
                        <label htmlFor="password_user">Contraseña</label>
                        <input type={showPass} className="form-control" name="Password" id="password_user" required ref={register} />
                        <span className='ico_show_pass' onClick={setInputPass}><i className={`fas ${showIco}`}></i></span>
                    </div>
                    <div className="text-right fw500">
                        <small>
                            <Link to="/forgot-password">
                                <span onClick={toggle}>
                                    Olvide mi contraseña
                                </span>
                            </Link>
                        </small>
                    </div>
                    <p className="text-center mt-4">
                        <button type="submit" className="btn">Entrar</button>
                    </p>
                </form>
            </div>
        </div>
    )
}
export default Login;
