import { callApi } from './service'

export const  countrySerice ={
    getAllCountry,
    getState
}

async function getAllCountry(){
    return callApi("/api/country/GetAllCountry",'get')
    .then(result=> {
        return result;
    })
}

async function getState(countryId){
    return callApi("/api/country/GetStateByCountry?countryId="+countryId,'get')
    .then(result=> {
        return result;
    })
}