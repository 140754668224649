import React from "react";
import { useForm } from 'react-hook-form'
import { verificationCardService } from '../../services/verificationCard.service';
import swal from "sweetalert";

import "./cardVerification.scss";

const CardVerification = (props) => {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        data.VerifiedCardId = props.match.params.id;
        verificationCardService.verifyCard(data)
            .then(function (response) {
                if (response.Code == 200) {
                    swal({ text: response.Message, icon: "success" });
                    props.history.push("/cards");
                } else {
                    swal({ text: response.Message, icon: "error" });
                }
            });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section className="wrapper_cardVerification">

                <div className="cardVerification">
                    <h3 className='cblue text-center'>Verificación de tarjeta</h3>

                    <div className='content_form_verf text-center'>
                        <h3 className='cblue text-center mb-1'>Ingresa el monto cobrado</h3>
                        <p className='fw-300 mb-4'>Se te ha realizado un cargo, por favor ingresa la cantidad exacta para confirmar que eres el propietario de la tarjeta</p>
                        <input
                            type='number'
                            step='0.01'
                            min='0.01'
                            max='99.99'
                            className='payment-card '
                            placeholder="$0.0"
                            name="ConfirmationAmount"
                            ref={register}
                        />
                        <button> Verificar tarjeta </button>
                    </div>
                </div>
            </section>
        </form>
    )
}
export default CardVerification;