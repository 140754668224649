import React from 'react'
import ItemProduct from '../itemProduct'
import AsideProvider from '../asideProvider'
import './containProvider.scss'

import { cartHook } from "../../hooks/cart.hook";
import {HeaderProviders} from "../providers/headerProviders";

const ContainProvider = (props) => {
    const outstandingProducts = props.OutstandingProducts;
    const products = props.CompanyProducts;

    const addPruchaseItem = (product) => {
        cartHook.addItem(product);
    };

    const style = {
        show: { display: 'flex' },
        hide: { display: 'none' }
    }
    return (
        <div className="contain_providers">
            <HeaderProviders
                title={props.Name}
                paragraph={props.Info}
                providerImg={props.UrlLogo}
            />

            <div className="wrapper_products_provider">
                <div className="wrapper_categ_mobile">
                    <AsideProvider urlImage={props.urlImage} urlVideo={props.UrlVideo} toggleCategories={props.toggleCategories} isAll={props.isAll} />
                </div>
                <h5 className="ttls fw600 mb-4">{props.isAll ? "Todos los productos" : "Lo más vendido"}</h5>
                <div className="content_products_provider row" style={!props.isAll ? style.show : style.hide}>
                    {outstandingProducts != undefined ?
                        outstandingProducts.map(item => {
                            return <ItemProduct {...item} settingProviderId={props.SettingProviderId} settingProviderName={props.SettingProviderName} providerName={props.Name} urlRoot={props.StorageRoot} addPruchaseItem={addPruchaseItem} urlLogoClrs={props.UrlLogoClrs} />
                        })
                        :
                        ""
                    }
                </div>
                <div className="content_products_provider row" style={props.isAll ? style.show : style.hide}>
                    {products != undefined ?
                        products.map(item => {
                            return <ItemProduct {...item} settingProviderId={props.SettingProviderId} settingProviderName={props.SettingProviderName} providerName={props.Name} urlRoot={props.StorageRoot} addPruchaseItem={addPruchaseItem} urlLogoClrs={props.UrlLogoClrs} />
                        })
                        :
                        ""
                    }
                </div>
            </div>

        </div>
    )
}

export default ContainProvider;
