import { callApi } from './service'

export const verificationCardService = {
    getList,
    verifyCard,
    deleteCard
}

async function getList() {
    return callApi('/api/VerificationCard/GetListByUserId', 'Get')
        .then((result) => {
            return result;
        }).catch((error) => {
            console.log(error);
            return [];
        });
}

async function verifyCard(data) {
    return callApi('/api/VerificationCard/VerifyCard', 'Post', data)
        .then((result) => {
            return result;
        });
}

async function deleteCard(data) {
    return callApi('/api/VerificationCard/DeleteCard', 'Post', data)
        .then((result) => {
            return result;
        });
}
