import React from 'react';
import './itemPurchaseDetails.scss'

const ItemPurchaseDetails = (props) => {

    return (
        <div className="box_item_purchase_details trans">
            <div >
                {props.Reference != "membership" && <span className="btn_delete_item" onClick={() => { props.deletePurchaseItemDetail(props.ProviderId, props.ProductId, props.Reference) }}><i className="far fa-trash-alt"></i></span>}
            </div>
            <div className="w-100 item_details">
                <p>{props.Name}</p>
                {props.Reference != "membership" && <p className="price_unit">{props.Reference == undefined ? "Precio individual $" + props.Price : props.SettingProviderName + ": " + props.Reference}</p>}
            </div>
            <div className="box_catidad">
                {props.Reference == undefined ?
                    <select className="custom-select custom-select-sm" onChange={(e) => { props.onChangeQuantity(props.ProviderId, props.ProductId, e.target.value) }} value={props.Quantity}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    :
                    ""
                }
            </div>
            <div className="box_item_total">${(props.Total).toLocaleString("es-mx", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</div>
        </div>
    )
}

export default ItemPurchaseDetails