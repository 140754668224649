const initialState = {
    whatsApp: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "Set_WhatsApp":
            return {
                ...state,
                whatsApp: action.payload,
            };
        default:
            return state;
    }
}
