import React, { useEffect, useState } from 'react';
import './providers.scss'
import { HeaderProviders } from "./headerProviders";
import { Alert, Badge, Button, Card, Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import swal from "sweetalert"
import { ModalError } from "../validations/modalError";
import { ModalSuccess } from "../validations/modalSuccess";
import { useSelector } from 'react-redux';
import { metaPixelService } from "../../services/metaPixel.service";
import redPay2 from "../../assets/red-pay-logo-xxx.png";
import BoxPayMethod from '../boxPayMethod';

export const ProvidersServices = (props) => {
    const user = useSelector((state) => state.user);
    const [steps, setSteps] = useState(1);
    const [modalError, setModalError] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [validReference, setValidReference] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);

    const [suscriptionItem, setSuscriptionItem] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productItems, setProductItems] = useState([]);
    const [dataService, setDataService] = useState({
        reference: "",//
        confirm: "",
        name: "",//
        amount: "",//
    })    
    const toggle = () => setModalError(!modalError);
    const toggleSuccess = () => setModalSuccess(!modalSuccess);


    useEffect(() => {
        var data = { content_type: 'product', content_ids: props.providerId, currency: 'mxn', content_category: 'provider', contents: [{ id: props.providerId, quantity: 1 }] }
        metaPixelService.trackEvent('track', 'ViewContent', data)
    }, [props.providerId])

    useEffect(() => {
        
        if (props.model) {
            var productsSorted = sortByKey(props.model.CompanyProducts, 'ProductPrice')
            setDataService({ ...dataService, productId: productsSorted[0].ProductId, })

            setSelectedProduct(
                {
                    productId: productsSorted[0].ProductId,
                    providerId: props.providerId,
                    name: '',
                    cant: 1,
                    productPrice: 0,
                    PercentageDiscount: null
                });
        }
    }, [props.model])

    useEffect(() => {
        if (user.isAuthenticated && !user.activeSubscription) {
            swal({ text: "Debes realizar el pago de tu suscripción para disfrutar de este beneficio", icon: "warning" }).then(() => {
                window.location.href = "/active-aside-purchase"
            });
        }

        if (user.isAuthenticated == false)
            window.location.href = "/"
    }, [user])

    const onChangeInput = (e) => {
        if (e.target.name === 'reference') {
            setDataService({ ...dataService, [e.target.name]: e.target.value })
            setValidReference(false)
        }
    }

    const handleMethodPay = (e) => setDataService({ ...dataService, paymentType: e.target.value })

    const handleAmount = (value) => {

        setDataService({ ...dataService, amount: value })
    }

    const handleConfirmReference = (e) => {
        if (e.target.value !== dataService.reference)
            setValidReference(false)
        if (e.target.value === dataService.reference)
            setValidReference(true)
    }
    const onSubmit = () => {
        if (dataService.paymentType === '')
            return toggle()
        toggleSuccess()
    }

    const nextStep = () => {
        if (validReference === false) return toggle();
        if (dataService.amount === 0 || dataService.reference === '')
            return toggle()

        let cart = JSON.parse(localStorage["pruchaseList"]);
        let suscriptionItemS = cart.find(p => p.ProviderId == 1)?.Items.find( p => p.ProductId == 4);
        if (suscriptionItemS != undefined) setSuscriptionItem(suscriptionItemS);
        
        let total = (dataService.amount);
        setTotalAmount(total);
        setSelectedProduct(
            {
                ...selectedProduct,
                    productPrice: total, 
            });


        setSteps(2);
    }

    function sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    return (
      
      <Row className="box_contain_providers">
        <Col xs={12} className="contain_providers">
          <HeaderProviders
            title={props.model.Name}
            paragraph={props.model.Info}
            providerImg={props.model.UrlLogo}
          />
          <div className="wrapper_products_provider">
            <h5 className="ttls fw600 mb-4">¡Paga tu servicio con nosotros!</h5>
            <Row className="content_products_provider">
              <div className="offset-lg-3 col-lg-6">
                {
                  {
                    1: (
                      <Card className="card_reload">
                        <div className="card_reload-content">
                          <h6 className="fw400 mb-2">Monto</h6>
                          <InputGroup size="lg" className="service">
                            <InputGroupText>$</InputGroupText>
                            <Input
                              placeholder="Indique el monto del pago del servicio"
                              id="amount"
                              name="amount"
                              type="text"
                              maxLength={6}
                              onChange={onChangeInput}
                              onKeyPress={(e) => {
                                e = e || window.e;
                                const charCode = e.keyCode || e.which;
                                const charStr = String.fromCharCode(charCode);
                                if (isNaN(charStr)) e.preventDefault();
                              }}
                              onKeyUp={(e) => {
                                const getNumber = (str) => {
                                  const arr = str.split("");
                                  const out = [];
                                  for (let cnt = 0; cnt < arr.length; cnt++) {
                                    if (isNaN(arr[cnt]) === false)
                                      out.push(arr[cnt]);
                                  }
                                  return Number(out.join(""));
                                };

                                const num = getNumber(e.target.value);

                                if (num === 0) {
                                  e.target.value = "";
                                  handleAmount(num);
                                }
                                if (num > 0) {
                                  handleAmount(num);
                                  e.target.value = num.toLocaleString();
                                }
                              }}
                            />
                          </InputGroup>
                          <h6 className="fw400 mb-2 mt-4">
                            Captura de referencia
                          </h6>
                          <Row noGutters>
                            <Col lg={6} md={6} sm={6} xs={12}>
                              <FormGroup className="mr-lg-1 mr-md-1 mr-sm-1 mr-0">
                                <Input
                                  size="lg"
                                  type="text"
                                  name="reference"
                                  placeholder="Referencia"
                                  onChange={onChangeInput}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={12}>
                              <FormGroup className="ml-lg-1 ml-md-1 ml-sm-1 ml-0">
                                <Input
                                  size="lg"
                                  type="text"
                                  name="confirmReference"
                                  placeholder="Confirmar referencia"
                                  onChange={handleConfirmReference}
                                  invalid={validReference !== true}
                                  disabled={dataService.reference.length === 0}
                                />
                                <FormFeedback>
                                  Tu referencia no concide
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="card_reload-footer">
                          <Button
                            color="primary"
                            type="button"
                            onClick={nextStep}
                          >
                            Confirmar
                          </Button>
                        </div>
                      </Card>
                    ),
                    2: (
                      <Card className="card_reload">
                        <div className="card_reload-content">
                          <Row noGutters className="card_reload-content-info">
                            <Col lg={4} md={4} sm={4} xs={12}>
                              <h6 className="fw400">Monto:</h6>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={12}>
                              <p className="mb-0">{dataService.amount}</p>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12} className="">
                              <h6 className="fw400">Referencia:</h6>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={12}>
                              <p className="mb-0">{dataService.reference}</p>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={12} className="mt-1">
                              <h6 className="fw400">Monto a pagar:</h6>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={12}>
                              <h4 className="mb-0">
                                <Badge color="success">$ {totalAmount}</Badge>
                              </h4>
                            </Col>
                            {suscriptionItem  ? (
                              <Col xs={12}>
                                <div className="mt-2">
                                  <Alert color="warning">*El saldo a favor no puede ser utilizado en recargas.</Alert>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                          <Row noGutters>
                            <Col xs={12}>
                              <h6 className="fw400 mb-2">
                                Elige tu método de pago
                              </h6>
                            </Col>
                            <Col xs={12}>
                              <div className="card_reload-payments">
                                <BoxPayMethod
                                  rechargeProducts={{
                                    ...selectedProduct,
                                    reference: dataService,
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="powered-by">
                                <p className="mb-0">Powered by:</p>
                                <img src={redPay2} alt="" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <div className="card_reload-footer">
                                            <Button color="primary" type="button" onClick={onSubmit}>Pagar</Button>
                                        </div> */}
                      </Card>
                    ),
                  }[steps]
                }
              </div>
            </Row>
          </div>
        </Col>
        <ModalError isOpen={modalError} toggle={toggle} />
        <ModalSuccess
          isOpen={modalSuccess}
          toggle={toggleSuccess}
          title="Pago exitoso"
          description="¡Gracias por pagar tu servicio con nosotros!"
        />
      </Row>
    );
}
