import React, { useEffect } from "react";
import { BasePaymentMethod } from "../paymentMethod/BasePaymentMethod";
import InputMask from "react-input-mask";
import swal from "sweetalert";
import { paymentMethodService } from '../../services/paymentMethod.service';
import { metaPixelService } from "../../services/metaPixel.service";
import { countrySerice } from "../../services/country.service";

import "./cardRegistration.scss";

export class CardRegistration extends BasePaymentMethod {
    constructor(props) {
        super(props)
        this.state = {
            currentPurchaseModel:props.location.state.model,
            isInProcess: false,
            FirstName: "",
            LastName: "",
            CardType: "",
            CardNumber: "",
            CardExpirationMonth: "",
            CardExpirationYear: "",
            Cvv: "",
            Countrys: [],
            States: [],
            CountryId: "",
            IdState: "",
            PostalCode: "",
            City: "",
            Street: "",
            StreetNumber: "",
            formValidators: {
                FirstName: null,
                LastName: null,
                CardNumber: null,
                CardExpirationMonth: null,
                CardExpirationYear: null,
                Cvv: null,
                PostalCode: null,
                City: null,
                Street: null,
                StreetNumber: null
            }
        }

    }
    componentWillMount() {
        this.getCountry();
    }

    getCountry = () => {
        countrySerice.getAllCountry()
            .then(result => {
                this.setState({ Countrys: result });
            });
    }

    changeCountry = (event) => {
        this.setState({ CountryId: event.target.value });
        countrySerice.getState(event.target.value)
            .then(result => {
                this.setState({ States: result });
            });
    }

    changeState = (event) => {
        this.setState({ IdState: event.target.value });
    }

    setCartType = (event) => {
        const value = event.target.value;
        let number = value.replace(/[-]/g, '');
        var re = new RegExp("^4");
        if (number.match(re) != null) {
            this.setState({ CardType: '001' })
        }
        var re = new RegExp("^5[1-5]");
        if (number.match(re) != null) {
            this.setState({ CardType: '002' })
        }
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({ [name]: value })
        await this.validateInputs(event.target)
    }

    handleChangeCard = async (e) => {
        this.setCartType(e);
        this.handleChange(e);
    }

    validateInputs = async (input) => {
        const name = input.name
        const value = input.value
        const formValidators = this.state.formValidators;
        switch (name) {
            case "FirstName":
            case "LastName":
            case "CardNumber":
            case "PostalCode":
            case "City":
            case "Street":
            case "StreetNumber":

            case "Cvv":
                formValidators[name] = value.length <= 0 ? "Éste campo es obligatorio" : ""
                break;
            case "CardExpirationYear":
                formValidators[name] = value.length != 4 ? "El año debe contener 4 dígitos" : ""
                break;
            case "CardExpirationMonth":
                formValidators[name] = value.length != 2 ? "El mes debe contener 2 dígitos" : ""
                break;
        }
        await this.setState({ formValidators })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const inputs = document.forms['formulario-pago'].getElementsByTagName("input");
        const validationInputs = Object.keys(this.state.formValidators)
        for (let input of inputs) {
            if (validationInputs.includes(input.name)) {
                this.validateInputs(input);
            }
        }
        let failedInputs;
        failedInputs = Object.values(this.state.formValidators).find(value => {
            return value == null || value.length > 0
        });

        if (failedInputs === undefined) {
            if (this.state.CountryId.length > 0 && this.state.IdState.length > 0) {
                this.setState({ isInProcess: true });
                this.setCurrentPurchaseToSession();
                paymentMethodService.registerCard(this.state)
                    .then(result => {
                        this.setState({ isInProcess: false });
                        if (result.Code == 200) {
                            window.location.href = result.Message;
                        } else {
                            swal({ text: result.Message, icon: "error" });
                        }
                    });
            }
            else
                swal({ text: "El país y estado son requeridos.", icon: "error" });
        }
    }

    setCurrentPurchaseToSession = () => {
        if (this.state.currentPurchaseModel.Email != undefined || this.state.currentPurchaseModel.Email != null) {
            let purchaseItem = {
                Email: this.state.currentPurchaseModel.Email,
                OrderId: this.state.currentPurchaseModel.OrderId,
                OrderNumber: this.state.currentPurchaseModel.OrderNumber,
                Amount: this.state.currentPurchaseModel.Amount,
                PaymentMethodId: this.state.currentPurchaseModel.PaymentMethodId,
                UrlSubmit: this.state.currentPurchaseModel.UrlSubmit
            }
            localStorage.setItem("currentPurchase", JSON.stringify( purchaseItem));
        }
    }

    ButtonProcess = () => {
        return !this.state.isInProcess ? <button type="submit" >Guardar tarjeta</button> :
            <button type="button" className="btn btn-primary" disabled>En proceso</button>
    }

    render() {
        const { formValidators } = this.state;
        return (
            <form onSubmit={this.handleSubmit} id="formulario-pago">
                <section className="wrapper_item_card_register wrapper_other container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="payment-side">
                                <h1>Tarjeta nueva</h1>
                                <div className=' payment-content mb-5'>
                                    <div className='row'>
                                        <div className="col-sm-6 col-md-5">
                                            <div>
                                                <h5 className='pvig'>Instrucciones</h5>
                                                <ol>
                                                    <li className='mb-3'>Introduzca los datos de la tarjeta con la que desea pagar (La información que ingrese está protegida por 3DSecure y SSL)</li>
                                                    <li className='mb-3'>Al ingresar los datos se le hará un cargo menor a $5.00 (cinco pesos mexicanos) reembolsable en su siguiente compra. Dicho monto deberá ser confirmado especificando pesos y centavos para confirmar que es usted el propietario de la tarjeta</li>
                                                    <li className='mb-3'>Revise en su aplicación o con su banco el monto que se le cargó e ingréselo donde se le solicita para completar su registro</li>
                                                    <li className='mb-3'>¡Listo! Ya es miembro del Club Godínez, con los mejores descuentos en productos digitales, ¡ahora sí rendirá la quincena!</li>
                                                </ol>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-7'>
                                            <div className="">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <p>Nombre</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.FirstName && 'error'}`}
                                                            name="FirstName"
                                                            value={this.state.FirstName}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.FirstName) && (
                                                            <small>{formValidators.FirstName}</small>
                                                        )}
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <p>Apellido</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.LastName && 'error'}`}
                                                            name="LastName"
                                                            value={this.state.LastName}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.LastName) && (
                                                            <small>{formValidators.LastName}</small>
                                                        )}
                                                    </div>
                                                </div>

                                                <p>Número de tarjeta</p>
                                                <InputMask
                                                    className={`payment-card ${formValidators.CardNumber && 'error'}`}
                                                    placeholder="XXXXXXXXXXXXXXXX"
                                                    mask="9999999999999999"
                                                    size="20"
                                                    name="CardNumber"
                                                    value={this.state.CardNumber}
                                                    onChange={this.handleChangeCard}
                                                    maskChar=""
                                                />
                                                {(formValidators.CardNumber) && (
                                                    <small>{formValidators.CardNumber}</small>
                                                )}
                                                <p>Fecha de expiración</p>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <InputMask
                                                            className={`payment-date ${formValidators.CardExpirationMonth && 'error'}`}
                                                            placeholder="MM"
                                                            mask="99"
                                                            maskChar=""
                                                            name="CardExpirationMonth"
                                                            value={this.state.CardExpirationMonth}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <InputMask
                                                            className={`payment-date ${formValidators.CardExpirationYear && 'error'}`}
                                                            placeholder="AAAA"
                                                            mask="9999"
                                                            maskChar=""
                                                            name="CardExpirationYear"
                                                            value={this.state.CardExpirationYear}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.CardExpirationMonth) && (
                                                            <small>{formValidators.CardExpirationMonth}</small>
                                                        )}
                                                        {(formValidators.CardExpirationYear) && (
                                                            <small>{formValidators.CardExpirationYear}</small>
                                                        )}
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p>Código de seguridad</p>
                                                        <InputMask
                                                            className={`payment-code ${formValidators.Cvv && 'error'}`}
                                                            mask="999"
                                                            maskChar=""
                                                            name="Cvv"
                                                            value={this.state.Cvv}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <span><i className="fa fa-credit-card card-i"></i></span>
                                                        <span className="card-text">3 dígitos al reverso de la tarjeta</span>
                                                        {(formValidators.Cvv) && (
                                                            <small>{formValidators.Cvv}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p>Dirección</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Código postal</p>
                                                        <input type="number"
                                                            maxLength={5}
                                                            className={`payment-card ${formValidators.PostalCode && 'error'}`}
                                                            name="PostalCode"
                                                            value={this.state.PostalCode}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.PostalCode) && (
                                                            <small>{formValidators.PostalCode}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <p>País</p>
                                                        <select className={`payment-card ${formValidators.CountryId && 'error'}`} onChange={this.changeCountry} name="CountryId">
                                                            <option selected>Selecciona una opción</option>
                                                            {
                                                                this.state.Countrys.length > 0 ?
                                                                    this.state.Countrys.map(item => <option key={item.CountryName} value={item.CountryId}>{item.CountryName}</option>) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <p>Estado</p>
                                                        <select className={`payment-card ${formValidators.StateId && 'error'}`} onChange={this.changeState} name="IdState">
                                                            <option selected>Selecciona una opción</option>
                                                            {
                                                                this.state.States.length > 0 ?
                                                                    this.state.States.map(item => <option key={item.Name} value={item.IdState}>{item.Name}</option>) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p>Ciudad</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.City && 'error'}`}
                                                            name="City"
                                                            value={this.state.City}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.City) && (
                                                            <small>{formValidators.City}</small>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p>Calle</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.Street && 'error'}`}
                                                            name="Street"
                                                            value={this.state.Street}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.Street) && (
                                                            <small>{formValidators.Street}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Núm. exterior</p>
                                                        <input type="text"
                                                            className={`payment-card ${formValidators.StreetNumber && 'error'}`}
                                                            name="StreetNumber"
                                                            value={this.state.StreetNumber}
                                                            onChange={this.handleChange} />
                                                        {(formValidators.StreetNumber) && (
                                                            <small>{formValidators.StreetNumber}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                {this.ButtonProcess()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        )
    }
}
