import React, { useEffect } from "react"
import { useState } from "react";
import { useForm } from 'react-hook-form'
import swal from "sweetalert"
import { registerService } from '../../services/register.service'
import { metaPixelService } from '../../services/metaPixel.service'

const Register = () => {
    const { register, handleSubmit } = useForm();
    const [registerReference, setRegisterReference] = useState([]);

    useEffect(() => {
        registerService.getRegisterReferernce().then(result => setRegisterReference(result));
    }, []);

    const onSubmit = (data, e) => {
        if (data.IAgree) {
            fetch('/api/Account/Register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(function (response) {
                if (response.ok) {
                    return response.json()
                }
            }).then(function (result) {
                if (result.Status) {
                    e.target.reset();
                    metaPixelService.trackRegisterEvent('track','CompleteRegistration', result.Data);
                    swal({ text: result.Message, icon: "success" });
                } else {
                    swal({ text: result.Message, icon: "error" });
                }
            }).catch(function (error) {
                console.log(error);
                swal({ text: "Error", icon: "error" });
            });
        } else {
            swal({ text: "Debe aceptar los términos y condiciones", icon: "warning" })
        }
    }

    return (
        <div className="content_logeo is_badge is_register">
            <div className="htop">
                <h5 className="ttls fw600 text-center text-light mb-4">Registro Nuevo</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label for="registerReference">¿Cómo te enteraste de nosotros?</label>
                                <select type="text" className="form-control form-control-sm" name="RegisterReference" id="registerReference" required ref={register} >
                                    {
                                        registerReference.length>0 && registerReference.map(item => <option value={item.Id}>{item.Name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="name-user">Nombre(s)</label>
                                <input type="text" className="form-control form-control-sm" name="FirstName" id="name-user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="lastname-user">Apellidos</label>
                                <input type="text" className="form-control form-control-sm" name="Surnames" id="lastname-user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="email-user"><i className="fas fa-calendar"></i> Fecha de nacimiento</label>
                                <input type="date" className="form-control form-control-sm" id="start" name="Birthday" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <p className="m-0"><label for="">Sexo</label></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="Gender" className="custom-control-input" value="M" ref={register} />
                                <label className="custom-control-label" for="customRadioInline1"><small>Masculino</small></label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="Gender" className="custom-control-input" value="F" ref={register} />
                                <label className="custom-control-label" for="customRadioInline2"><small>Femenino</small></label>
                            </div>
                        </div>

                        <div className="col-sm-7">
                            <div className="form-group">
                                <label for="email-user">Correo electrónico</label>
                                <input type="email" className="form-control form-control-sm" name="Email" id="email-user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="form-group">
                                <label for="phoneNumber_user">Teléfono</label>
                                <input type="tel" className="form-control form-control-sm" name="PhoneNumber" id="phoneNumber_user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="password_user">Password</label>
                                <input type="password" className="form-control form-control-sm" name="Password" id="password_user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="password_user">Confirma password </label>
                                <input type="password" className="form-control form-control-sm" name="ConfirmPassword" id="password_user_confirm" required ref={register} />
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="custom-control custom-checkbox mb-2">
                            <input type="checkbox" className="custom-control-input" name='IAgree' id="customCheck1" ref={register} />
                            <label className="custom-control-label" for="customCheck1"><small>Acepto términos y condiciones</small></label>
                        </div>
                        <button type="submit" className="btn">Registrarse</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Register;