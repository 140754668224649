import React, { Component } from "react";
import HubContainer from "../components/hubContainer";
import CouponsSlider from "../components/couponsSlider/couponsSlider";
import ItemPromoHome from "../components/itemPromoHome";
import BannerPromoHome from "../components/bannerPromoHome";
import { FormEmbassador } from "../components/formEmbassador/formEmbassador";
import ModalMembership from "../components/modalMembership/modalMembership";
import { WrapperProviderIndividual } from "../components/wrapperProviderIndividual";
import WrapperBullets from "../components/wrapperBullets";
import { SliderHome } from "../components/sliderHome";
import $ from "jquery";
import {Link} from "react-router-dom";

export class Home extends Component {
  static displayName = Home.name;
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      carrousel: [],
    };
  }

  model = () => {
    fetch("/api/Home/Hub", { method: "GET" })
      .then((response) => response.json())
      .then((result) =>
        this.setState({
          providers: result.Providers,
          carrousel: result.Carrousel,
        })
      )
      .catch((error) => console.log("error", error));
  };

  componentWillMount() {
    this.model();
  }

  componentDidMount() {
    $("html, body").scrollTop(0);
  }

  render() {
    return (
      <section>
        <div className="wrapper_welcome">
          <SliderHome carrousel={this.state.carrousel} />
        </div>

        <div className="my_container">
          <CouponsSlider />
          {/* == ADS - PUBLICIDAD HORIZONTAL == */}
          {/* <div className="ads_horizontal">
            <a href="#">
              <img src="./imgs/ads/banner-ver.jpg" alt="ADS" />
            </a>
          </div> */}
          <div className="drawer__hub v-mobile">
            <HubContainer providers={this.state.providers} />
            {/* == ADS - PUBLICIDAD VERTICAL == */}
            {/* <div className="ads_vertical">
              <a href="#">
                <img
                  className="b-desktop"
                  src="./imgs/ads/banner-hor.jpg"
                  alt="ADS"
                />
                <img
                  className="b-mobile"
                  src="./imgs/ads/banner-drop.jpg"
                  alt="ADS"
                />
              </a>
            </div> */}
          </div>

          {/*<ItemPromoHome />*/}
          {/*<BannerPromoHome />*/}
          {/*<WrapperProviderIndividual />*/}
          <WrapperBullets />
          <FormEmbassador />
        </div>
      </section>
    );
  }
}
