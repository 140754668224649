import React from 'react'
import Slider from "react-slick";
import './wrapperBullets.scss'

const WrapperBullets = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        responsive: [
        
            {
                breakpoint: 769,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 540,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 380,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                },
            },
        ],

       };

  return (
    <div className="wrapper_bullets">
      <div className="text-center">
              <h5 className="ttls fw500 m-0 font-italic">Únete al Club</h5>
        <h1 className="ttls fw700"><span className="c_primary">#Red</span>Godínez</h1>
      </div>
          <Slider {...settings}>
              <div className=" box_item_bullets">
                  <div className='img_bullets'>
                      <img src="./imgs/bullets/bullet-1.png" alt="Red Godinez. Adquiere tus productos a precios increíbles, en comparación de los precios comerciales" />
                  </div>
                  <h5>Por ti</h5>
                  <p>Todo lo que ves aquí, cómpralo con  descuento.</p>
              </div>
              <div className=" box_item_bullets">
                  <div className='img_bullets'>
                      <img src="./imgs/bullets/bullet-2.png" alt="Red Godinez. Encuentra opciones para todos los estilos de Godín, en grupo maximizamos la diversión." />
                  </div>
                  <h5>En Banda</h5>
                  <p>La membresía te sale más bara que un café. Por solo $39.00 al mes.</p>
              </div>
              <div className=" box_item_bullets">
                  <div className='img_bullets'>
                      <img src="./imgs/bullets/bullet-3.png" alt="Red Godinez. Todas tus compras y cupones son inmediatos y enviados vía electrónica." />
                  </div>
                  <h5>Es Pro</h5>
                  <p>El Lic. asegura que la operación es segura y tus compras llegarán de inmediato a tu mail.</p>
              </div>
              <div className=" box_item_bullets">
                  <div className='img_bullets'>
                      <img src="./imgs/bullets/bullet-4.png" alt="Red Godinez. Compra y canjea tus descuentos en el momento que quieras y en el lugar donde te encuentres. " />
                  </div>
                  <h5>Y Promuévete</h5>
                  <p>De clásico Godín pasa a ser el Godín Alfa. Trae a tus colegas y métele un 15% a tu cartera de cada referido/suscripción</p>
              </div>   
          </Slider>
    
    </div>
  )
}

export default WrapperBullets;
