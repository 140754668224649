import React, { useState } from 'react';
import ItemPurchaseDetails from '../itemPurchaseDetails'
import './itemPurchase.scss'

const ItemPurchase = (props) => {
    const [activeAccordion, setStateAccordion] = useState('')
    const [activeChevron, setStateChevron] = useState('')

    function AsidePurchaseBox() {
        setStateAccordion(activeAccordion === '' ? 'show' : '')
        setStateChevron(activeChevron === '' ? 'open' : '')
    }

    const items = props.Items;
    return (
        <div className="accordion item_purchase" id="accordionExample">
            {props.ProviderId != 1 && <span className="btn_delete_group trans" onClick={() => { props.deletePurchaseItem(props.ProviderId, props.ProviderName) }}><i className="far fa-trash-alt"></i> <span>Eliminar</span></span>}
            <div className="card">
                <div className="card-header" id="headingOne" >
                    <div className="box_logo_shop">
                        <span className="bg_logo_shop">
                            <img src={props.Logo} alt="" />
                        </span>
                    </div>
                    <div className="w-100">
                        <h5 className="ttls_sections m-0">{props.ProviderName}</h5>
                        {props.ProviderId!=1 ?
                            <p className="to_shop"><a href={`/provider?id=${props.ProviderId}`}>Ir a la tienda <i className="fas fa-chevron-right trans"></i></a></p>
                            :
                            <p className="to_shop"><a href="/">Ir al home<i className="fas fa-chevron-right trans"></i></a></p>

                            }
                    </div>
                    <div className="lastdata">
                        <h5 className="ttls_sections">${(props.Total).toLocaleString("es-mx", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</h5>
                        <span className={`ico_chevron ${activeChevron}`} onClick={AsidePurchaseBox}>
                            <i className='fas fa-chevron-down'></i>
                        </span>
                    </div>
                </div>
                <div id="collapseOne" className={`collapse ${activeAccordion}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                        {
                            items.map(itemDetail => {
                                return <ItemPurchaseDetails {...itemDetail} deletePurchaseItemDetail={props.deletePurchaseItemDetail} onChangeQuantity={props.onChangeQuantity} />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemPurchase