import React from 'react';

import './providers.scss'

export const HeaderProviders = (props) => {
    const displayName = HeaderProviders.name;
    return (
        <div className="contain_info_provaider">
            <div className="box_info_provaider">
                <h2 className="ttls_provider">{props.title}</h2>
                <p className="info_provider">{props.paragraph}</p>
            </div>
            <div className="box_logo_provaider">
                <img src={props.providerImg} alt={props.title+props.providerImg} />
            </div>
        </div>
    );
}
