import React from 'react'
import { useSelector } from "react-redux";

import './whatsAppButton.scss'

export default function WhatsAppButton() {
    const phone = useSelector(state => state.settings.whatsApp);
    //console.log(phone)

    if (!phone) return <></>;
    return (
        <a
            href={`https://wa.me/${phone}`}
            className="whatsapp-button"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src="./imgs/WhatsApp.svg"
                alt="WhatsApp"
                className="whatsapp-icon"
            />
        </a>
    )
}
