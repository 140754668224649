import React from 'react';
import { Modal } from 'reactstrap';
import Spinner from 'react-spinkit';
import './blockScreen.scss';

const BlockScreen = ({ blocking}) => {

    return (
        <Modal isOpen={blocking} className="wrapper_block">
            <div className="box_content_block">
                <Spinner name="ball-pulse-rise" color="#e94242" />
            </div>
        </Modal>
    )
}

export default BlockScreen