import React, { Component } from 'react';
import $ from 'jquery'

export class NoticePrivacy extends Component {
    static displayName = NoticePrivacy.name;


    componentDidMount() {
        $('html, body').scrollTop(0);
    }


    render() {
        const style = {
            bgSections: { background: 'url(./imgs/bglegal.jpg)' }
        }

        return (
            <section className="wrapper_legal ptop">
                <div className="bg_sections" style={style.bgSections}></div>
                <div className="container content_legal">
                    <h2 className="ttls fw600 c_white mb-3">Aviso de privacidad</h2>
                    <div className="box_legal">
                        <p>En cumplimiento a lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la Ley), ponemos a su disposición el presente Aviso de Privacidad (en lo sucesivo referido como “Aviso”) en los siguientes términos:</p>
                        <h6>I. IDENTIDAD Y DOMICILIO DEL RESPONSABLE DE LA PROTECCIÓN DE DATOS PERSONALES.</h6>
                        <p>Red Voucher Systems, S.A. de C.V., (“Red Voucher” o “el Responsable”) con domicilio ubicado en la calle Texas 39, Colonia Nápoles, Delegación Benito Juárez, C.P.03810, Ciudad de México será el responsable de recabar, almacenar y proteger los datos personales de sus clientes y proveedores. La persona encargada del manejo de datos personales es Luis Villavicencio Padilla, la cual puede ser contactada en el siguiente correo electrónico: lvillavicencio@redcompanies.com.mx; o bien, en el domicilio de Red Voucher.</p>
                        <h6>II. CONSENTIMIENTO DEL TITULAR. </h6>
                        <p>Para efectos de lo dispuesto en la Ley y demás legislación aplicable, el Titular manifiesta: (i) que el presente Aviso le ha sido dado a conocer por el Responsable; (ii) haber leído, entendido y acordado los términos expuestos en este Aviso, por lo que otorga su consentimiento respecto del tratamiento de sus datos personales. En caso de que los datos personales recopilados incluyan datos patrimoniales o financieros, mediante la firma del contrato correspondiente, sea en formato impreso o utilizando medios electrónicos y sus                         correspondientes procesos para la formación del consentimiento, se llevarán a cabo actos que constituyan el consentimiento expreso del titular y (iii) que otorga su consentimiento para que Red Voucher o sus Encargados realicen transferencias y/o remisiones de datos personales en términos del apartado V del presente Aviso. Será responsabilidad exclusiva del cliente de Red Voucher la actualización de sus datos personales, así como su veracidad y exactitud. En todo momento se asume de buena fe que la información ha sido proporcionada por su legítimo propietario o poseedor de los derechos legales para ello, en ambos casos mayor de edad, por lo que Red Voucher no será responsable de la misma más que en los términos establecidos en el presente Aviso.</p>
                        <h6>III. DATOS PERSONALES RECABADOS.</h6>
                        <p>Red Voucher recabará los siguientes datos personales: nombre completo, teléfono fijo o móvil y correo electrónico; tipo de tarjeta bancaria (Visa o Mastercard), nombre del titular de la tarjeta, número de tarjeta, fecha de expiración y código de seguridad.</p>
                        <p>Red Voucher no recaba datos personales sensibles, definidos en el artículo 3 de la Ley.</p>
                        <h6>IV. FINALIDAD DE LOS DATOS PERSONALES. </h6>
                        <p>El Responsable, recaba los datos personales para los siguientes fines: Finalidades primarias. Red Voucher tratará los datos personales del Titular con la finalidad de llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas de cualquier relación jurídica y comercial que establezca con motivo de la prestación de sus servicios, tales como: identificación del cliente; contacto con el cliente; facturación; cobranza y atención al cliente.</p>
                        <p>Finalidades secundarias. Asimismo, Red Voucher tratará datos personales para otras finalidades como enviar notificación de ofertas, avisos y/o mensajes promocionales; comunicaciones con fines de mercadotecnia o publicidad; realizar encuestas; sacar estadísticas.</p>
                        <p>El Titular puede oponerse al tratamiento de sus datos para las finalidades secundarias a través de los medios puestos a su disposición para el ejercicio de sus derechos ARCO. En caso de no oponerse en un plazo de cinco días hábiles posteriores a que sus datos fueron recabados, se entenderá que ha otorgado su consentimiento.</p>
                        <h6>V. TRANSFERENCIAS Y/O REMISIONES DE DATOS.</h6>
                        <p>En virtud de la firma del presente Aviso, usted autoriza expresamente a Red Voucher a transmitir vía electrónica y/o físicamente, copia de sus identificaciones oficiales y todos y cada uno de los documentos requeridos para identificarlo y brindarle servicios de venta de cupones electrónicos.</p>
                        <p>Red Voucher transmitirá los datos que usted le haya proporcionado o que haya recibido de forma legítima, independientemente de la fuente, cuando le sea requerido por orden de autoridad judicial. Al momento de proporcionar sus datos personales, usted libera a Red Voucher de cualquier responsabilidad en la que pudiera incurrir por recabar o transmitir sus datos en cumplimiento de la prestación del servicio que recibe.</p>
                        <p>Sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por personas distintas a esta empresa. En ese sentido, su información puede ser compartida con nuestros anunciantes, patrocinadores, publicistas, contratistas, socios comerciales y/o terceros con quién Red Voucher tenga celebrados o celebre diversos acuerdos comerciales tanto en territorio nacional como en el extranjero, para enviar, a discreción de Red Voucher, notificación de ofertas, avisos, publicidad y/o mensajes promocionales de acuerdo a sus intereses                         específicos, ya sean propios o de terceros, proporcionar datos estadísticos a anunciantes potenciales o llevar a cabo comunicaciones con fines de mercadotecnia, publicitarios o telemarketing; realizar investigaciones de mercadeo; realizar programas de beneficios e incentivos y en general para mejorar nuestros servicios. Su información personal también puede transferirse, almacenarse y procesarse en un país distinto de donde se proporcionó. Sí lo hacemos, transferimos la información de conformidad con las leyes de protección de la información aplicables. Tomamos medidas para proteger la información personal sin importar el país donde se almacena o a donde se transfiere. Tenemos procedimientos y controles oportunos para procurar esta protección.</p>
                        <h6>VI. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES.</h6>
                        <p>Red Voucher resguardará los datos personales y patrimoniales o financieros bajo estrictas medidas de seguridad administrativas, técnicas y físicas que han sido implementadas en términos del Reglamento de la LFPDP, con el objeto de proteger y garantizar los datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.</p>
                        <p>Sin embargo, Red Voucher no garantiza que terceros no autorizados no puedan tener acceso a los sistemas físicos o lógicos de los Titulares o del Responsable o en los documentos electrónicos y archivos almacenados en sus sistemas. Por lo tanto, Red Voucher no será en responsable en ningún caso de los daños y perjuicios que se puedan derivar de dicho acceso no autorizado.</p>
                        <p>El Titular o su representante legal debidamente acreditado, podrán limitar el uso o divulgación de sus datos personales a través de los mismos medios y procedimientos dispuestos para el ejercicio de los Derechos ARCO. En caso de que dicha solicitud resulte procedente, el Titular será registrado en el listado de exclusión dispuesto por Red Voucher para dejar de recibir información relativa a campañas publicitarias o de mercadotecnia.</p>
                        <p>En suma, el Titular se encuentra asistido del derecho de inscribirse en el Registro Público para Evitar Publicidad (REPEP) de la PROFECO http://repep.profeco.gob.mx.</p>
                        <p>Asimismo, si el Titular considera que Red Voucher ha vulnerado el derecho a la protección de datos personales, puede acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (“INAI”).</p>
                        <h6>VII. PROCEDIMIENTO PARA EJERCER LOS DERECHOS ARCO.</h6>
                        <p>En todo momento, el Titular tiene derecho de acceder, rectificar y cancelar sus datos; oponerse al tratamiento de los mismos o revocar el consentimiento que haya proporcionado. Para esto, deberá presentar una solicitud en formato libre y que deberá contener la información y documentación siguiente:</p>
                        <p>a. Nombre del Titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</p>
                        <p>b. Los documentos vigentes que acrediten su identidad (copia simple en formato impreso o electrónico de su credencial de elector, pasaporte o visa) o, en su caso, la representación legal del Titular (copia simple en formato impreso o electrónico de la carta poder simple con firma autógrafa del Titular, el mandatario y sus correspondientes identificaciones oficiales vigentes – credencial de elector, pasaporte o Visa);</p>
                        <p>c. La descripción clara y precisa de los datos respecto de los que busca ejercer alguno de los Derechos ARCO, y</p>
                        <p>d. Cualquier otro elemento o documento que facilite la localización de los datos personales del Titular.</p>
                        <p>En el caso de las solicitudes de rectificación de datos personales, el Titular deberá también indicar las modificaciones a realizarse y aportar la documentación que sustente su petición. Para dar cumplimiento a la obligación de acceso a sus datos personales, se hará previa acreditación de la identidad del titular o personalidad del representante; poniendo la información a disposición en sitio en el domicilio del Responsable. Si la información solicitada así lo permite, se podrá acordar otro medio entre el Titular y el Responsable.</p>
                        <p>Para la petición del formato, recepción y respuesta de las solicitudes para ejercer los derechos ARCO, la revocación de consentimiento y los demás derechos previstos en la Ley, se puede poner en contacto con Luis Villavicencio Padilla en el siguiente correo electrónico: lvillavicencio@redcompanies.com.mx. De igual forma podrán llamar al teléfono: +52 (55)55959476, de lunes a viernes en un horario de 8 a.m. a 7 p.m o bien, acudir a las oficinas ubicadas en el domicilio mencionado en el punto I de este Aviso.</p>
                        <p>En caso de que la información proporcionada en la solicitud sea errónea o insuficiente o no se acompañen los documentos de acreditación correspondientes, Red Voucher podrá solicitar, dentro de los cinco días hábiles siguientes a la recepción de la solicitud, que el Titular aporte los elementos o documentos necesarios para dar trámite a la misma. El Titular contará con diez días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente.</p>
                        <p>Red Voucher responderá al Titular en un plazo máximo de veinte días hábiles, contados desde la fecha en que se recibió la solicitud a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince días hábiles siguientes a que se comunique la respuesta. En todos los casos, la respuesta se dará por la misma vía por la que se haya presentado la solicitud o en su caso, por cualquier otro medio acordado con el Titular. Los plazos antes referidos podrán ser ampliados en términos de la Ley.</p>
                        <h6>VIII. RECOLECCIÓN DE DATOS AL NAVEGAR EN SITIOS Y PÁGINAS WEB DE RED VOUCHER.</h6>
                        <p>Red Voucher puede recabar datos a través de sus sitios Web, o mediante el uso de herramientas de captura automática de datos. Dichas herramientas le permiten recolectar la información que envía su navegador a dichos sitios Web, tales como el tipo de navegador que utiliza, el idioma de usuario, los tiempos de acceso y la dirección IP de sitios Web que utilizó para acceder a los sitios del Responsable o sus Encargados. Estos datos se recaban con la finalidad identificar al cliente y brindarle un mejor servicio durante el tiempo que usa la página web.</p>
                    </div>

                </div>
            </section>
        );
    }
}
