import { callApi } from './service'
import { metaPixelService } from './metaPixel.service';

export const paymentMethodService = {
    registerCard
}

async function registerCard(data) {
    return callApi('/api/PaymentMethod/RegisterCard', 'Post', data)
        .then((result) => {
            var pixelData = {content_category: 'Payment', currency: 'MXN', value: 0.0}
            metaPixelService.trackEvent('track','AddPaymentInfo', pixelData)
            return result;
        });
}
